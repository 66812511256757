import React from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { join } from "lodash";
import { connect } from "react-redux";

import Page from "../layouts/Page";
import GraftStep1 from "../components/GraftStep1";
import GraftStep2 from "../components/GraftStep2";
import GraftStep3 from "../components/GraftStep3";
import GraftStep4 from "../components/GraftStep4";
import GraftStep5 from "../components/GraftStep5";
import GraftStep6 from "../components/GraftStep6";
import IsValidGraft from "../components/IsValidGraft";

import Title from "../components/Title";
import Progress from "../components/Progress";

function New({ currentGraft }) {
  const location = useLocation("/blog/:slug");

  return (
    <Page withBack={location.pathname !== "/graft/1"}>
      {location.pathname !== "/graft/1" ? (
        <IsValidGraft currentGraft={currentGraft} />
      ) : null}
      <Title>
        Code Greffon <br />
        {join(currentGraft, ", ")}
      </Title>

      <Switch>
        <Route path="/graft/1" exact={true}>
          <Progress percentage={0} />
          <GraftStep1 currentGraft={currentGraft} />
        </Route>
        <Route path="/graft/2" exact={true}>
          <Progress percentage={0} />
          <GraftStep2 />
        </Route>
        <Route path="/graft/3" exact={true}>
          <Progress percentage={20} />
          <GraftStep3 />
        </Route>
        <Route path="/graft/4" exact={true}>
          <Progress percentage={40} />
          <GraftStep4 />
        </Route>
        <Route path="/graft/5" exact={true}>
          <Progress percentage={60} />
          <GraftStep5 />
        </Route>
        <Route path="/graft/6" exact={true}>
          <Progress percentage={80} />
          <GraftStep6 />
        </Route>
        <Redirect to="/graft/1" />
      </Switch>
    </Page>
  );
}

const mapStateToProps = state => ({
  currentGraft: state.prescription.code
});

export default connect(mapStateToProps)(New);
