import React from "react";
import ReactSVG from "react-svg";
import styled from "styled-components/macro";
import { get } from "lodash";

import cameraIcon from "../img/camera.svg";

const LaunchCamera = styled.div`
  background: rgb(239, 239, 239, 0.4);
  border-radius: 10px;
  padding: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 200px;

  > div {
    margin-top: 35px;
  }
`;

function Scanner({ handleDetect }) {
  return (
    <LaunchCamera
      onClick={() => {
        if (!get(window, "plugins.GMVBarcodeScanner")) {
          alert("scanner non disponible");
          return;
        }

        window.plugins.GMVBarcodeScanner.scan({}, function(err, result) {
          //Handle Errors
          if (err) {
            console.log(err);
            return;
          }

          handleDetect(result);
        });
      }}
    >
      <ReactSVG src={cameraIcon} />
      <div>Scannez le code barre</div>
    </LaunchCamera>
  );
}

export default Scanner;
