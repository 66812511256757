import React from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  margin: 45px 0 90px;
  h1,
  h2,
  h3 {
    margin-top: 45px;
    font-weight: bold;
  }

  img {
    max-width: 100%;
    height: auto;
  }

  table {
    border-collapse: collapse;
  }
  th {
    background: #ccc;
  }

  th,
  td {
    border: 1px solid #ccc;
    padding: 8px;
  }

  tr:nth-child(even) {
    background: #efefef;
  }

  tr:hover {
    background: #d1d1d1;
  }
`;

function Content({ children, className }) {
  return <Wrapper className={className}>{children}</Wrapper>;
}

export default styled(Content)``;
