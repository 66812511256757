import React, { useEffect } from "react";
import ReactSVG from "react-svg";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { connect, useDispatch } from "react-redux";

import Page from "../layouts/Page";
import Button from "../components/Button";
import Input from "../components/Input";
import Checkbox from "../components/Checkbox";
import Title from "../components/Title";

import { getToken } from "../api";
import { updatePractitioner } from "../redux/practitioner";
import { isAuthenticatedSelector } from "../redux/selectors";

import logo from "../img/logo.svg";
import logoRegion from "../img/logo-region.svg";

const loginSchema = Yup.object().shape({
  practitionerCode: Yup.string()
    .trim()
    .required("Requis"),
  email: Yup.string()
    .email("Email invalide")
    .required("Requis"),
  keep_logged: Yup.bool()
});

const Wrapper = styled(Page)`
  height: 100vh;
  ${Title} {
  }
`;

const Logos = styled.div`
  margin-bottom: 30px;
  text-align: center;

  svg {
    height: 125px;
  }
`;

const Footer = styled.div`
  text-align: center;
  line-height: 1.2;
  margin-top: 30px;
  span {
    opacity: 0.5;
    font-size: 12px;
  }
  svg {
    height: 40px;
    margin-top: 20px;
  }
`;

function Login({ isAuthenticated }) {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuthenticated) {
      history.push("/graft");
    }
  }, [history, isAuthenticated]);

  return (
    <Wrapper withNav={true} withBack={false}>
      <div
        css={`
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
        `}
      >
        <Logos>
          <ReactSVG src={logo} />
        </Logos>
        <Title
          css={`
            text-align: center;
          `}
        >
          Se connecter
        </Title>
        <Formik
          initialValues={{ email: "", practitionerCode: "", keep_logged: true }}
          validationSchema={loginSchema}
          onSubmit={async (values, { setFieldError }) => {
            try {
              const { token } = await getToken(values.practitionerCode);
              dispatch(updatePractitioner({ ...values, token }));
              history.push("/graft/1");
            } catch (error) {
              console.error(error);
              setFieldError(
                "practitionerCode",
                "Aucune correspondance n’a été trouvé entre votre numéro de client et le code greffon, merci de bien vouloir recommencer ou contacter notre service client."
              );
            }
          }}
        >
          {({ isValid, handleSubmit, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Field
                type="email"
                name="email"
                placeholder="Saisissez votre adresse email"
                component={Input}
              />
              <Field
                type="text"
                name="practitionerCode"
                placeholder="Saisissez votre numéro client"
                component={Input}
                autocomplete="true"
                autoCapitalize="characters"
                onChange={e => {
                  setFieldValue(
                    "practitionerCode",
                    e.target.value.toUpperCase()
                  );
                }}
              />

              <div>
                <Field
                  type="checkbox"
                  name="keep_logged"
                  component={Checkbox}
                  label="Se souvenir de moi"
                />
              </div>

              <Button type="submit" disabled={!isValid}>
                Connexion
              </Button>
            </form>
          )}
        </Formik>
        <Footer>
          <span>
            La Région Auvergne Rhône-Alpes soutient les Laboratoires O.S.T
          </span>
          <ReactSVG src={logoRegion} />
        </Footer>
      </div>
    </Wrapper>
  );
}

const mapStateToProps = state => ({
  keep_logged: state.practitioner.keep_logged,
  isAuthenticated: isAuthenticatedSelector(state)
});

export default connect(mapStateToProps)(Login);
