import { configureStore, getDefaultMiddleware } from "redux-starter-kit";
import { combineReducers } from "redux";
import { persistStore, persistReducer, PURGE } from "redux-persist";
import storage from "redux-persist/lib/storage";

import ui from "./ui";
import practitioner from "./practitioner";
import prescription from "./prescription";
import grafts from "./grafts";
import indications from "./indications";
import surgeons from "./surgeons";

const persistConfig = {
  key: "root",
  storage
};

const appReducer = combineReducers({
  ui,
  practitioner,
  prescription,
  grafts,
  indications,
  surgeons
});

const rootReducer = (state, action) => {
  if (action.type === PURGE) {
    state = undefined;
  }

  return appReducer(state, action);
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: false
  })
});

export const persistor = persistStore(store);

export default store;
