import React from "react";

import Content from "../components/Content";
import Page from "../layouts/Page";

function CGU() {
  return (
    <Page>
      <Content>
        <h1>
          Conditions g&eacute;n&eacute;rales d&rsquo;utilisation de
          l&rsquo;application mobile / portail web
        </h1>

        <h2>PREAMBULE</h2>

        <p>
          Les pr&eacute;sentes conditions g&eacute;n&eacute;rales
          d&rsquo;utilisation (ci-apr&egrave;s d&eacute;nomm&eacute;es
          &laquo;&nbsp;<strong>CGU</strong>&nbsp;&raquo;), ont pour objet de
          d&eacute;terminer les r&egrave;gles d&rsquo;utilisation de
          l&rsquo;application mobile &laquo;&nbsp;EZ OST&nbsp;&raquo; ou du
          portail web d&eacute;di&eacute; (ci-apr&egrave;s
          d&eacute;nomm&eacute;s l&rsquo;&laquo;&nbsp;
          <strong>Application </strong>&raquo;), &eacute;dit&eacute;e par la
          soci&eacute;t&eacute; OST DEVELOPPEMENT (ci-apr&egrave;s
          d&eacute;nomm&eacute;e la &laquo;&nbsp;
          <strong>Soci&eacute;t&eacute;</strong>&nbsp;&raquo;),
          Soci&eacute;t&eacute; par actions simplifi&eacute;e, au capital social
          de 457&nbsp;200 euros, immatricul&eacute;e au Registre du Commerce et
          des Soci&eacute;t&eacute;s sous le num&eacute;ro SIREN
          389&nbsp;472&nbsp;465, ayant son si&egrave;ge social au 15 Rue Georges
          Besse, 63100 &agrave; Clermont-Ferrand, France.
        </p>
        <p>
          Le directeur de la publication est Hubert GUIBOURT, en sa
          qualit&eacute; de Pr&eacute;sident de la Soci&eacute;t&eacute;.{" "}
        </p>
        <p>
          En installant et/ou en acc&eacute;dant &agrave; l&rsquo;Application,
          tout utilisateur (ci-apr&egrave;s d&eacute;nomm&eacute; &laquo;&nbsp;
          <strong>Utilisateur</strong>&nbsp;&raquo;) accepte sans r&eacute;serve
          l&rsquo;int&eacute;gralit&eacute; des pr&eacute;sentes CGU et
          s&rsquo;engage &agrave; respecter les obligations &agrave; sa charge.{" "}
        </p>
        <p>
          Les CGU expriment l&rsquo;int&eacute;gralit&eacute; de l&rsquo;accord
          entre l&rsquo;Utilisateur et la Soci&eacute;t&eacute;, applicable lors
          de toute utilisation de l&rsquo;Application. La Soci&eacute;t&eacute;
          se r&eacute;serve le droit de modifier ces CGU en les mettant &agrave;
          jour &agrave; tout moment. La version des CGU applicable entre
          l&rsquo;Utilisateur et la Soci&eacute;t&eacute; est celle en vigueur
          au moment de la connexion et de l&rsquo;utilisation de
          l&rsquo;Application.{" "}
        </p>
        <p>
          La Soci&eacute;t&eacute; conseille donc aux Utilisateurs de consulter
          r&eacute;guli&egrave;rement ces CGU afin de prendre connaissance de
          celles en vigueur lors de l&rsquo;utilisation de l&rsquo;Application.
        </p>

        <h2>ARTICLE&nbsp;1 : D&eacute;finitions</h2>

        <p>
          Les termes d&eacute;butant par une majuscule au sein des
          pr&eacute;sentes CGU, utilis&eacute;s indiff&eacute;remment au
          singulier ou au pluriel, auront la signification suivante&nbsp;:{" "}
        </p>
        <ul>
          <li>
            &laquo;&nbsp;<strong>Application</strong>&nbsp;&raquo;&nbsp;:
            d&eacute;signe l&rsquo;application mobile logicielle &laquo;&nbsp;EZ
            OST&nbsp;&raquo; / portail web &eacute;dit&eacute;e et fournie par
            la Soci&eacute;t&eacute;, donnant acc&egrave;s aux Services, qui est
            disponible dans l&rsquo;Apple Store d&rsquo;Apple et le Google Play
            Store de Google, pour &ecirc;tre t&eacute;l&eacute;charg&eacute;e
            par l&rsquo;Utilisateur sur son terminal Apple iOS et Android.
            L&rsquo;Application comprend &eacute;galement les Contenus,
            logiciels, programmes, bases de donn&eacute;es, syst&egrave;mes
            d&rsquo;exploitation, documentation et tout autre
            &eacute;l&eacute;ment et services qui la compose, les mises &agrave;
            jour et les nouvelles versions de l&rsquo;Application
            apport&eacute;es par la Soci&eacute;t&eacute;.
          </li>
          <li>
            &laquo;&nbsp;<strong>CGU</strong>&nbsp;&raquo;&nbsp;: d&eacute;signe
            les pr&eacute;sentes Conditions G&eacute;n&eacute;rales
            d&rsquo;Utilisation de l&rsquo;Application. Elles sont accessibles
            en permanence en ligne sur l&rsquo;Application.
          </li>
          <li>
            &laquo;&nbsp;<strong>Contenu</strong>&nbsp;&raquo;&nbsp;:
            d&eacute;signe la structure de l&rsquo;Application et ses contenus
            tels que le contenu &eacute;ditorial, les images, la charte
            graphique, les marques, logos, sigles, les d&eacute;nominations
            sociales, ainsi que tout autre contenu pr&eacute;sent au sein de
            l&rsquo;Application.
          </li>
          <li>
            {" "}
            &laquo;&nbsp;<strong>Services</strong>&nbsp;&raquo;&nbsp;:
            d&eacute;signe les diff&eacute;rentes fonctionnalit&eacute;s
            propos&eacute;es par l&rsquo;Application.
          </li>
          <li>
            &laquo;&nbsp;<strong>Soci&eacute;t&eacute;</strong>
            &nbsp;&raquo;&nbsp;: d&eacute;signe la soci&eacute;t&eacute; OST
            DEVELOPPEMENT, &eacute;ditrice de l&rsquo;Application.
          </li>
          <li>
            &laquo;&nbsp;<strong>Utilisateur</strong>&nbsp;&raquo;&nbsp;:
            d&eacute;signe toute personne physique majeure ayant
            t&eacute;l&eacute;charg&eacute; et valid&eacute; le processus
            d&rsquo;inscription aux services de l&rsquo;Application, pour ses
            besoins propres, dans le cadre d&rsquo;un usage non commercial, sans
            but lucratif direct ou indirect.{" "}
          </li>
        </ul>

        <h2>
          ARTICLE 2 : Objet et fonctionnalit&eacute;s de l&rsquo;Application
        </h2>
        <p>
          La Soci&eacute;t&eacute; est sp&eacute;cialis&eacute;e dans la
          fabrication de greffons osseux viro-inactiv&eacute;s. En tant que
          banque de tissus, la Soci&eacute;t&eacute; propose des allogreffes
          trait&eacute;es aupr&egrave;s d&rsquo;&eacute;quipes chirurgicales
          dans le besoin, lors d&rsquo;interventions sp&eacute;cifiques.
        </p>
        <p>
          La Soci&eacute;t&eacute; a d&eacute;velopp&eacute; une Application
          ayant pour objectif de r&eacute;pondre &agrave; son obligation
          d&rsquo;assurer la tra&ccedil;abilit&eacute; de ses produits
          implantables, dans le cadre de leur implantation de greffe osseuse.{" "}
        </p>
        <p>
          L&rsquo;Application met &agrave; disposition de l&rsquo;Utilisateur,
          les Services suivants&nbsp;:
        </p>
        <ul>
          <li>
            Ajouter un code Greffon, en scannant le code barre ou en
            saisissant&nbsp;le num&eacute;ro de s&eacute;rie ;{" "}
          </li>
          <li>
            Renseigner la fiche d&rsquo;administration ou la prescription
            m&eacute;dicale et consulter le certificat de validation
            correspondants au code Greffon indiqu&eacute; ou
            t&eacute;l&eacute;charger le document vierge&nbsp;;
          </li>
          <li>
            Indiquer la date de l&rsquo;intervention, les coordonn&eacute;es
            relatives &agrave; l&rsquo;identit&eacute; du patient receveur,
            l&rsquo;identit&eacute; du chirurgien implanteur, les indications
            chirurgicales, ainsi que les commentaires &eacute;ventuels ou autres
            observations li&eacute;es &agrave; l&rsquo;op&eacute;ration&nbsp;;
          </li>
          <li>Recevoir par email, le document g&eacute;n&eacute;r&eacute;.</li>
        </ul>
        <p>
          L&rsquo;Application a vocation &agrave; s&rsquo;adresser &agrave; des
          Utilisateurs professionnels, et notamment du domaine m&eacute;dical,
          compos&eacute;s notamment de chirurgiens et de secr&eacute;taires
          m&eacute;dicales.
        </p>
        <p>
          Les fonctionnalit&eacute;s de l&rsquo;Application peuvent &ecirc;tre
          enrichies ou modifi&eacute;es &agrave; tout moment par la
          Soci&eacute;t&eacute;, sans que sa responsabilit&eacute; ne puisse
          &ecirc;tre engag&eacute;e &agrave; ce titre.
        </p>

        <h2>
          ARTICLE&nbsp;3 : Acc&egrave;s &agrave; l&rsquo;Application et aux
          Services
        </h2>
        <p>
          Pour acc&eacute;der et utiliser l&rsquo;Application,
          l&rsquo;Utilisateur doit &ecirc;tre une personne majeure et
          poss&eacute;der un t&eacute;l&eacute;phone compatible ou un terminal
          mobile ainsi qu&rsquo;un acc&egrave;s au r&eacute;seau Internet.
        </p>
        <p>
          L&rsquo;application est t&eacute;l&eacute;chargeable depuis les
          plateformes de l&rsquo;Apple Store d&rsquo;Apple et le Google Play
          Store de Google.
        </p>
        <p>
          Une fois l&rsquo;Application install&eacute;e sur le terminal de
          l&rsquo;Utilisateur, ce dernier doit se connecter en renseignant une
          adresse email, personnelle ou professionnelle, et son num&eacute;ro
          client.{" "}
        </p>
        <p>
          Ce num&eacute;ro de client est unique &agrave;
          l&rsquo;&eacute;tablissement de sant&eacute; (Cabinet dentaire, Centre
          hospitalier, clinique&hellip;), au sein duquel le(s) Utilisateur(s)
          est/sont employ&eacute;(s). Il est communiqu&eacute; sur les factures
          et/ou bons de livraison &eacute;tablis avec cet &eacute;tablissement
          ou sur simple demande au service client.{" "}
        </p>
        <p>
          Une fois les informations renseign&eacute;es, l&rsquo;Utilisateur
          recevra sur l&rsquo;adresse email fournie pr&eacute;alablement, le
          document g&eacute;n&eacute;r&eacute;.
        </p>
        <p>
          L&rsquo;Utilisateur s&rsquo;engage, lors de son utilisation de
          l&rsquo;Application, &agrave; fournir des informations vraies, exactes
          et aussi compl&egrave;tes que possible. Il s&rsquo;engage
          &eacute;galement &agrave; mettre &agrave; jour ces informations dans
          les meilleurs d&eacute;lais, en cas de changement de celles-ci.
        </p>
        <p>
          La version du logiciel de l&rsquo;Application peut &ecirc;tre mise
          &agrave; jour pour ajouter de nouvelles fonctions et/ou de nouveaux
          Services.
        </p>
        <h2>ARTICLE&nbsp;4 : Conditions financi&egrave;res</h2>
        <p>
          L&rsquo;Application est t&eacute;l&eacute;chargeable gratuitement
          depuis la plateforme de l&rsquo;Apple Store d&rsquo;Apple et le Google
          Play Store de Google, hors co&ucirc;ts d&rsquo;abonnement
          aupr&egrave;s de l&rsquo;op&eacute;rateur t&eacute;l&eacute;phonique
          de l&rsquo;Utilisateur, hors co&ucirc;ts de connexion et
          d&rsquo;acc&egrave;s au r&eacute;seau Internet et hors surco&ucirc;t
          &eacute;ventuel factur&eacute; pour le chargement des donn&eacute;es.{" "}
        </p>
        <p>
          L&rsquo;acquisition des &eacute;quipements ad&eacute;quats
          rel&egrave;ve du seul choix, de la responsabilit&eacute; exclusive et
          est &agrave; la charge exclusive de l&rsquo;Utilisateur.
        </p>

        <h2>ARTICLE&nbsp;5 : Propri&eacute;t&eacute; intellectuelle</h2>
        <p>
          La Soci&eacute;t&eacute; est le titulaire exclusif de tous les droits
          de propri&eacute;t&eacute; intellectuelle portant sur
          l&rsquo;Application et ses Contenus.
        </p>
        <p>
          Les Contenus sont au titre des droits d&rsquo;auteur et/ou autres
          droits de propri&eacute;t&eacute; intellectuelle, la
          propri&eacute;t&eacute; exclusive de leurs &eacute;diteurs respectifs.
          Toute copie, reproduction, repr&eacute;sentation, exploitation,
          adaptation, alt&eacute;ration, modification, traduction, diffusion,
          int&eacute;grale ou partielle, des Contenus, qu&rsquo;ils
          appartiennent &agrave; la Soci&eacute;t&eacute; ou &agrave; un tiers
          qui a conc&eacute;d&eacute; des droits &agrave; la
          Soci&eacute;t&eacute;, par quelque proc&eacute;d&eacute; que ce soit,
          est illicite.{" "}
        </p>
        <p>
          Les Contenus pr&eacute;sent&eacute;s au sein de l&rsquo;Application
          sont susceptibles de modification sans pr&eacute;avis et sont mis
          &agrave; disposition sans aucune garantie d&rsquo;aucune sorte,
          expresse ou tacite et ne peuvent donner lieu &agrave; un quelconque
          droit &agrave; d&eacute;dommagement. Les Contenus
          prot&eacute;g&eacute;s peuvent notamment &ecirc;tre identifi&eacute;s
          par les mentions de r&eacute;serve suivantes&nbsp;:
          &laquo;&nbsp;&copy;&nbsp;&raquo; ou &laquo;&nbsp;Tous droits
          r&eacute;serv&eacute;s&nbsp;&raquo;.
        </p>
        <p>
          Les noms et logos apparaissant sur l&rsquo;Application sont des
          marques d&eacute;pos&eacute;es. Ces signes distinctifs sont la
          propri&eacute;t&eacute; exclusive de la Soci&eacute;t&eacute; ou de
          tiers ayant conc&eacute;d&eacute; des droits &agrave; la
          Soci&eacute;t&eacute;. Toute utilisation, reproduction totale ou
          partielle, ou imitation de ces marques&nbsp;est interdite, au sens des
          articles L.713-2 et suivants du Code de la propri&eacute;t&eacute;
          intellectuelle, sauf autorisation pr&eacute;alable et expresse de la
          Soci&eacute;t&eacute;.
        </p>
        <p>
          Sans pr&eacute;judice des dispositions de cet article, aucune
          disposition des pr&eacute;sentes CGU ne peut &ecirc;tre
          interpr&eacute;t&eacute;e comme une cession, un transfert, une vente,
          une concession, un pr&ecirc;t, une location, une autorisation
          d&rsquo;exploitation consentie directement ou indirectement par la
          Soci&eacute;t&eacute; au profit de l&rsquo;Utilisateur sur
          l&rsquo;Application, son Contenu ou ses Services.
        </p>

        <h2>ARTICLE 6&nbsp;: Licence d&rsquo;utilisation</h2>

        <p>
          La Soci&eacute;t&eacute; conc&egrave;de &agrave; l&rsquo;Utilisateur,
          un droit personnel d&rsquo;utilisation de l&rsquo;Application et de
          ses Services, non exclusif, r&eacute;vocable, non cessible, non
          transf&eacute;rable, mondial et gratuit, uniquement pour ses besoins
          propres dans le cadre de l&rsquo;utilisation de l&rsquo;Application,
          &agrave; l&rsquo;exclusion de toute autre finalit&eacute; que les
          Services d&eacute;finis pr&eacute;c&eacute;demment.
        </p>
        <p>
          Il est strictement interdit &agrave; l&rsquo;Utilisateur
          d&rsquo;acc&eacute;der et/ou d&rsquo;utiliser et/ou de tenter
          d&rsquo;acc&eacute;der ou d&rsquo;utiliser les codes source ou objet
          de l&rsquo;Application et/ou des composants logiciels de
          l&rsquo;Application.{" "}
        </p>
        <p>
          L&rsquo;Utilisateur n&rsquo;acquiert aucun droit de
          propri&eacute;t&eacute; intellectuelle sur l&rsquo;Application, le
          Contenu et/ou les Services ni aucun autre droit que ceux
          conf&eacute;r&eacute;s par les pr&eacute;sentes.
        </p>
        <p>
          Enfin, l&rsquo;Utilisateur s&rsquo;engage express&eacute;ment &agrave;
          ce que l&rsquo;utilisation de l&rsquo;Application ne porte en aucun
          cas atteinte aux droits de la Soci&eacute;t&eacute;.
        </p>

        <h2>ARTICLE&nbsp;7 : Obligations de l&rsquo;Utilisateur</h2>

        <p>L&rsquo;Utilisateur s&rsquo;engage express&eacute;ment&nbsp;: </p>
        <ul>
          <li>
            A t&eacute;l&eacute;charger l&rsquo;Application sur son
            &eacute;quipement exclusivement pour un usage professionnel et non
            commercial&nbsp;;
          </li>
          <li>
            A ne pas reproduire de fa&ccedil;on permanente ou provisoire
            l&rsquo;Application, en tout ou partie, par tout moyen que sous
            quelque forme que ce soit&nbsp;;
          </li>
          <li>
            A ne pas utiliser de logiciels ou proc&eacute;d&eacute;s
            destin&eacute;s &agrave; copier le Contenu sans l&rsquo;autorisation
            pr&eacute;alable &eacute;crite de la Soci&eacute;t&eacute;&nbsp;;
          </li>
          <li>
            A ne pas proc&eacute;der &agrave; toute adaptation, modification,
            traduction, transcription, arrangement, compilation,
            d&eacute;compilation, assemblage, d&eacute;sassemblage, transcodage,
            ni appliquer la r&eacute;tro-ing&eacute;nierie de toute ou partie de
            l&rsquo;Application, des Services et/ou du Contenu&nbsp;;
          </li>
          <li>
            A ne pas exporter l&rsquo;Application, ni fusionner tout ou partie
            de l&rsquo;Application avec d&rsquo;autres programmes
            informatiques&nbsp;;
          </li>
          <li>
            A renoncer express&eacute;ment &agrave; utiliser des logiciels ou
            dispositifs susceptibles de perturber le bon fonctionnement de
            l&rsquo;Application&nbsp;;
          </li>
          <li>
            A ne pas extraire ou r&eacute;utiliser, y compris &agrave; des fins
            priv&eacute;es, sans autorisation pr&eacute;alable &eacute;crite de
            la Soci&eacute;t&eacute;, une partie substantielle ou non du contenu
            des bases de donn&eacute;es et archives constitu&eacute;es par
            l&rsquo;Application&nbsp;;
          </li>
          <li>
            A ne pas mettre en place des syst&egrave;mes susceptibles de pirater
            l&rsquo;Application et/ou son Contenu en tout ou partie, ou de
            nature &agrave; violer les pr&eacute;sentes CGU&nbsp;;
          </li>
          <li>
            A informer la Soci&eacute;t&eacute; d&egrave;s la connaissance
            d&rsquo;un acte de piratage et en particulier de toute utilisation
            illicite ou non contractuelle de l&rsquo;Application et/ou de son
            Contenu&nbsp;;
          </li>
          <li>
            A ne pas vendre, louer, c&eacute;der ou distribuer de quelque
            fa&ccedil;on que ce soit, l&rsquo;Application et/ou son Contenu
            &agrave; des tiers.
          </li>
          <li>
            A obtenir le consentement explicite et pr&eacute;alable du patient
            pour la collecte de ses donn&eacute;es personnelles.
          </li>
        </ul>

        <h2>ARTICLE&nbsp;8 : Disponibilit&eacute; de l&rsquo;Application</h2>

        <p>
          Hors cas de force majeure, tels que d&eacute;finis par la
          jurisprudence fran&ccedil;aise, la Soci&eacute;t&eacute;
          s&rsquo;engage &agrave; mettre en &oelig;uvre tous les moyens
          n&eacute;cessaires afin d&rsquo;assurer au mieux la
          disponibilit&eacute; et l&rsquo;acc&egrave;s &agrave;
          l&rsquo;Application.{" "}
        </p>
        <p>
          Ces Services sont disponibles 24heures sur 24 et 7 jours sur 7, sous
          r&eacute;serve d&rsquo;&eacute;ventuelles pannes et p&eacute;riodes
          d&rsquo;interruption n&eacute;cessaires &agrave; la mise &agrave; jour
          de l&rsquo;Application.
        </p>
        <p>
          La Soci&eacute;t&eacute; se r&eacute;serve le droit
          d&rsquo;interrompre, de suspendre momentan&eacute;ment ou de modifier
          sans pr&eacute;avis, l&rsquo;acc&egrave;s &agrave; tout ou partie des
          Services sans que cela n&rsquo;ouvre droit &agrave; aucune obligation
          ni indemnisation. Des interventions de maintenance n&eacute;cessaires
          au bon fonctionnement de l&rsquo;Application pourront &ecirc;tre
          effectu&eacute;es sans que l&rsquo;Utilisateur ait &eacute;t&eacute;
          pr&eacute;alablement averti.
        </p>
        <p>
          La Soci&eacute;t&eacute; ne peut &ecirc;tre tenue responsable de
          l&rsquo;impossibilit&eacute; d&rsquo;acc&eacute;der &agrave;
          l&rsquo;Application pour quelque raison que ce soit, ni de
          l&rsquo;interruption de la connexion Internet de l&rsquo;Utilisateur.
        </p>

        <h2>ARTICLE&nbsp;9 : Limites de garantie</h2>

        <p>
          <strong>9.1</strong> L&rsquo;Utilisateur accepte et reconna&icirc;t
          que son utilisation de l&rsquo;Application, y compris les informations
          qu&rsquo;il diffuse, est faite sous son unique et enti&egrave;re
          responsabilit&eacute;.{" "}
        </p>
        <p>
          L&rsquo;Utilisateur de l&rsquo;Application renseigne de mani&egrave;re
          sinc&egrave;re et exacte, les informations demand&eacute;es. La
          responsabilit&eacute; de la Soci&eacute;t&eacute; ne saurait
          &ecirc;tre engag&eacute;e en cas de d&eacute;clarations
          incompl&egrave;tes, inexactes ou mensong&egrave;res de
          l&rsquo;Utilisateur.{" "}
        </p>
        <p>
          <strong>9.2 </strong>L&rsquo;acc&egrave;s et l&rsquo;utilisation de
          l&rsquo;Application se font aux risques et p&eacute;rils de
          l&rsquo;Utilisateur. L&rsquo;Application est fournie &laquo;&nbsp;tel
          quelle&nbsp;&raquo; et &laquo;&nbsp;comme disponible&nbsp;&raquo;,
          sans garantie que ce soit.&nbsp;
        </p>
        <p>
          L&rsquo;Utilisateur d&eacute;clare conna&icirc;tre et accepter les
          caract&eacute;ristiques et limites du r&eacute;seau Internet et
          notamment, les caract&eacute;ristiques fonctionnelles et performances
          techniques du r&eacute;seau Internet, les probl&egrave;mes li&eacute;s
          &agrave; la connexion et/ou &agrave; l&rsquo;acc&egrave;s au
          r&eacute;seau Internet, les probl&egrave;mes li&eacute;s &agrave; la
          disponibilit&eacute; et &agrave; l&rsquo;encombrement des
          r&eacute;seaux, les probl&egrave;mes li&eacute;s &agrave; la
          d&eacute;faillance ou &agrave; la saturation des r&eacute;seaux, les
          probl&egrave;mes li&eacute;s au temps de transit, &agrave;
          l&rsquo;acc&egrave;s aux informations mises en ligne, aux temps de
          r&eacute;ponse pour afficher, consulter, interroger ou autrement
          transf&eacute;rer des donn&eacute;es, les risques
          d&rsquo;interruption&hellip; pour lesquelles la responsabilit&eacute;
          de la Soci&eacute;t&eacute; ne saurait &ecirc;tre engag&eacute;e.
        </p>
        <p>
          Dans ces conditions, la Soci&eacute;t&eacute; ne peut &ecirc;tre tenue
          pour responsable&nbsp;:{" "}
        </p>
        <ul>
          <li>
            En cas de d&eacute;faut, de perte, de retard ou d&rsquo;erreur de
            transmission de donn&eacute;es, ind&eacute;pendants de sa
            volont&eacute;&nbsp;;
          </li>
          <li>
            De l&rsquo;envoi de messages et/ou donn&eacute;es &agrave; une
            adresse fausse, erron&eacute;e ou incompl&egrave;te&nbsp;;
          </li>
          <li>
            Si des donn&eacute;es ne lui parvenaient pas, pour quelque raison
            que ce soit ou si les donn&eacute;es re&ccedil;ues &eacute;taient
            illisibles ou impossibles &agrave; traiter&nbsp;;
          </li>
          <li>
            Du cas o&ugrave; l&rsquo;Utilisateur ne parviendrait pas &agrave;
            acc&eacute;der ou utiliser l&rsquo;Application et/ou ses Services
            pour quelque raison que ce soit&nbsp;;
          </li>
          <li>
            Si la connexion devait &ecirc;tre interrompue, pour quelque raison
            que ce soit.
          </li>
        </ul>
        <p>
          <strong>9.3 </strong>Il appartient &agrave; l&rsquo;Utilisateur de
          prendre toutes les mesures appropri&eacute;es de fa&ccedil;on &agrave;
          prot&eacute;ger ses propres donn&eacute;es et/ou logiciels
          stock&eacute;s sur ses &eacute;quipements informatique et
          t&eacute;l&eacute;phonique contre toute atteinte, la
          Soci&eacute;t&eacute; ne pouvant en aucun cas en &ecirc;tre tenue pour
          responsable.
        </p>
        <p>
          Par cons&eacute;quent, la Soci&eacute;t&eacute; ne peut &ecirc;tre
          tenue pour responsable :{" "}
        </p>
        <ul>
          <li>
            D&rsquo;une incompatibilit&eacute; de l&rsquo;Application ou de
            dysfonctionnements avec certains &eacute;quipements de
            l&rsquo;Utilisateur&nbsp;;
          </li>
          <li>
            De toute r&eacute;clamation ou proc&eacute;dure faite &agrave;
            l&rsquo;encontre de l&rsquo;Utilisateur pour l&rsquo;utilisation
            faite de l&rsquo;Application, dont il est seul responsable&nbsp;;
          </li>
          <li>
            En cas de modification ou de mises &agrave; jour sans pr&eacute;avis
            d&rsquo;informations communiqu&eacute;es dans l&rsquo;Application,
            fournies &agrave; titre indicatif et non contractuelles&nbsp;;
          </li>
          <li>
            D&rsquo;&eacute;ventuelles omissions et/ou erreurs que pourrait
            contenir l&rsquo;Application, lors d&rsquo;am&eacute;liorations ou
            de modifications de l&rsquo;Application&nbsp;;
          </li>
          <li>
            En cas de dommages de toute nature, directs ou indirects,
            r&eacute;sultant de l&rsquo;utilisation ou de
            l&rsquo;incapacit&eacute; &agrave; utiliser l&rsquo;Application.
          </li>
        </ul>

        <h2>ARTICLE 10 : R&eacute;siliation </h2>

        <p>
          La Soci&eacute;t&eacute; peut mettre fin &agrave; l&rsquo;utilisation
          de l&rsquo;Application &agrave; tout moment, en donnant un
          pr&eacute;avis de r&eacute;siliation. A ce titre, tous les droits et
          licences accord&eacute;s &agrave; l&rsquo;Utilisateur seront
          r&eacute;sili&eacute;s, et ce dernier devra cesser toute utilisation
          de l&rsquo;Application.
        </p>

        <h2>ARTICLE 11 : Contact &ndash; Service client</h2>

        <p>
          Pour toute question ou r&eacute;clamation relative &agrave;
          l&rsquo;Application et/ou aux Services, l&rsquo;Utilisateur peut
          contacter le Service Client par&nbsp;:
        </p>
        <ul>
          <li>
            Courrier postal&nbsp;: OST D&eacute;veloppement, Service
            informatique, 15 Rue Georges Besse 63017 Clermont-Ferrand Cedex 2
          </li>
          <li>T&eacute;l&eacute;phone&nbsp;: 04.73.98.14.28 </li>
          <li>
            Mail&nbsp;:{" "}
            <a href="mailto:systeme-information@ost-dev.com">
              systeme-information@ost-dev.com
            </a>
          </li>
        </ul>

        <h2>ARTICLE 12 : Droit applicable</h2>

        <p>Les pr&eacute;sentes CGU sont soumises au droit fran&ccedil;ais.</p>
        <p>
          Le tribunal comp&eacute;tent en cas de litige est le Tribunal de
          Commerce de CLERMONT-FERRAND.
        </p>
        <p>
          En pr&eacute;alable &agrave; toute action en justice, les Parties
          devront rechercher ensemble une solution amiable, telle qu&rsquo;une
          m&eacute;diation conventionnelle ou tout autre mode alternatif de
          r&egrave;glement des diff&eacute;rends.
        </p>
      </Content>
    </Page>
  );
}

export default CGU;
