import React from "react";
import MaskedInput from "react-text-mask";
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import styled from "styled-components/macro";
import Label from "./Label";

export const InputStyled = styled.input`
  display: block;
  width: 100%;
  box-shadow: none;
  border: 0;
  background: rgba(239, 239, 239, 0.4);
  padding: 15px;
  border-radius: 10px;
  border: 1px solid transparent;
  border-color: ${props => (props.error ? "#E61746" : "")};

  &[type="date"],
  &[type="time"] {
    -webkit-appearance: none;
  }

  &[type="number"]::-webkit-inner-spin-button,
  [type="number"]::-webkit-outer-spin-button {
    height: auto;
  }

  &[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
  }

  &[type="search"]::-webkit-search-cancel-button,
  &[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
  }

  &:placeholder-shown {
    opacity: 0.9;
    color: rgba(0, 0, 0, 0.4);
  }

  &::-ms-placeholder {
    color: var(--input-fore-color);
    opacity: 0.54;
  }
`;

const TextArea = styled.textarea`
  min-height: 220px;
  display: block;
  width: 100%;
  box-shadow: none;
  border: 0;
  background: rgba(239, 239, 239, 0.4);
  padding: 20px;
  border-radius: 10px;
  border: 1px solid transparent;
  border-color: ${props => (props.error ? "#E61746" : "")};
`;

export const Error = styled.div`
  padding: 5px;
  border-radius: 10px;
  margin-top: 5px;
  color: #e61746;
`;

export const Wrapper = styled.div`
  margin-bottom: ${props => (props.noMargin ? "0" : "30px")};
  position: relative;

  label {
    display: block;
  }
`;

const dateMask = [/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/];
const autoCorrectedDatePipe = createAutoCorrectedDatePipe("dd/mm/yyyy");

function matchInput({ field, touched, errors, props }) {
  switch (props.type) {
    case "text":
    case "email":
      return (
        <InputStyled
          id={field.name}
          {...field}
          {...props}
          error={touched[field.name] && errors[field.name]}
        />
      );

    case "textarea":
      return (
        <TextArea
          id={field.name}
          {...field}
          {...props}
          error={touched[field.name] && errors[field.name]}
        />
      );

    case "date":
      return (
        <MaskedInput
          mask={dateMask}
          pipe={autoCorrectedDatePipe}
          keepCharPositions={true}
          {...field}
          render={(ref, maskedInputProps) => (
            <InputStyled
              type="text"
              className={props.className}
              autoComplete="off"
              ref={ref}
              error={touched[field.name] && errors[field.name]}
              placeholder="JJ/MM/AAAA"
              inputMode="numeric"
              {...maskedInputProps}
            />
          )}
        />
      );
    default:
      return null;
  }
}

const Input = ({ field, form: { touched, errors }, ...props }) => (
  <Wrapper noMargin={props.noMargin}>
    <div>
      {props.label ? (
        <Label
          htmlFor={props.id || field.name}
          error={touched[field.name] && errors[field.name]}
        >
          {props.label}
        </Label>
      ) : null}
      {matchInput({ field, touched, errors, props })}
    </div>
    {touched[field.name] && errors[field.name] && (
      <Error>{errors[field.name]}</Error>
    )}
  </Wrapper>
);

export default Input;
