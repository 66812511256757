import React from "react";

import Content from "../components/Content";

import aide1 from "../img/greffon-aide-1.png";
import aide2 from "../img/greffon-aide-2.png";

function CodeGreffon() {
  return (
    <Content>
      <p>Sur le document présent dans la boite du greffon</p>
      <img src={aide1} alt="" />
      <p>Sur la boite du greffon</p>
      <img src={aide2} alt="" />
    </Content>
  );
}

export default CodeGreffon;
