import { createSlice } from "redux-starter-kit";

const practitioner = createSlice({
  name: "practitioner",
  initialState: {
    email: null,
    practitionerCode: null,
    token: null,
    keep_logged: false
  },
  reducers: {
    updatePractitioner: (state, action) => ({ ...state, ...action.payload }),
    resetToken: (state, action) => {
      state.token = null;
    }
  }
});

export const { updatePractitioner, resetToken } = practitioner.actions;
export default practitioner.reducer;
