import React from "react";
import styled from "styled-components/macro";

const StyledCheckbox = styled.input`
  &:focus {
    outline: 0 none;
  }

  &[type="checkbox"],
  &[type="radio"] {
    appearance: none;
    position: relative;
    height: 24px;
    width: 24px;
    padding: 0;
    border-radius: 8px;
    display: inline-block;
    margin: 0;
    vertical-align: top;
    background: #efefef;
    flex-shrink: 0;
    + label {
      margin-left: 15px;
      line-height: 24px;
      vertical-align: middle;
      color: ${props => (props.error ? "#E61746" : "inherit")};
    }
  }

  &[type="checkbox"]:checked,
  &[type="radio"]:checked {
    background: #00a3e0;
    background: var(--main-color);

    :before {
      position: absolute;
      content: "✓";
      color: #fff;
      font-size: 18px;
      top: -1px;
      left: 4px;
    }
  }
`;

const Error = styled.div`
  padding: 5px;
  border-radius: 10px;
  margin-top: 5px;
  color: #e61746;
`;

function Checkbox({
  field, // { name, value, onChange, onBlur }
  form: { touched, errors }, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
  ...props
}) {
  return (
    <div
      css={`
        position: relative;
      `}
    >
      <div
        css={`
          display: flex;
        `}
      >
        <StyledCheckbox
          id={field.name}
          {...field}
          {...props}
          error={touched[field.name] && errors[field.name]}
        />
        {props.label ? (
          <label
            htmlFor={props.id || field.name}
            error={touched[field.name] && errors[field.name]}
          >
            {props.label}
          </label>
        ) : null}
      </div>
      {touched[field.name] && errors[field.name] && (
        <Error>{errors[field.name]}</Error>
      )}
    </div>
  );
}

export default Checkbox;
