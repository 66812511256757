import React from "react";

import Content from "../components/Content";
import Page from "../layouts/Page";

function PolitiqueDeConfidentialités() {
  return (
    <Page>
      <Content>
        <h1>
          Confidentialit&eacute; &ndash; Protection des donn&eacute;es
          personnelles
        </h1>

        <h2>PREAMBULE</h2>

        <p>
          La Soci&eacute;t&eacute; est une banque de tissu autoris&eacute;e par
          les autorit&eacute;s de police sanitaire, ayant pour obligation
          d&rsquo;assurer la tra&ccedil;abilit&eacute; de ses produits
          implantables, dans le cadre d&rsquo;implantation de greffe osseuse.
          Elle accorde une grande valeur &agrave; l&rsquo;honn&ecirc;tet&eacute;
          et a &agrave; c&oelig;ur de construire avec ses Utilisateurs, une
          relation forte fond&eacute;e sur la confiance et
          l&rsquo;int&eacute;r&ecirc;t mutuel.
        </p>
        <p>
          Conform&eacute;ment &agrave; cette philosophie, la protection des
          donn&eacute;es personnelles de ses Utilisateurs est essentielle
          &agrave; ses yeux et la Soci&eacute;t&eacute; souhaite donc les
          informer par l&rsquo;interm&eacute;diaire de cette politique de
          confidentialit&eacute;, de la mani&egrave;re dont elle collecte et
          traite ces donn&eacute;es.
        </p>
        <p>
          La soci&eacute;t&eacute; OST DEVELOPPEMENT, soci&eacute;t&eacute; par
          actions simplifi&eacute;e, au capital social de 457&nbsp;200 euros,
          immatricul&eacute;e au Registre du Commerce et des
          Soci&eacute;t&eacute;s sous le num&eacute;ro SIREN
          389&nbsp;472&nbsp;465, ayant son si&egrave;ge social au 15 Rue Georges
          Besse, 63100 &agrave; Clermont-Ferrand, France agit en tant que
          Responsable de traitement.
        </p>
        <p>
          La Soci&eacute;t&eacute; se r&eacute;serve le droit de modifier cette
          Politique de confidentialit&eacute; afin de garantir sa
          conformit&eacute; avec le droit en vigueur. L&rsquo;Utilisateur est
          donc invit&eacute; &agrave; consulter r&eacute;guli&egrave;rement la
          pr&eacute;sente afin de se tenir inform&eacute; des &eacute;ventuels
          changements.
        </p>
        <p>
          La derni&egrave;re mise &agrave; jour de la pr&eacute;sente politique
          de confidentialit&eacute; est intervenue le&nbsp;: 18 octobre 2019.
        </p>

        <h2>
          PRINCIPES GENERAUX EN MATIERE DE TRAITEMENT DE DONNEES PERSONNELLES
        </h2>

        <p>
          La Soci&eacute;t&eacute; s&rsquo;engage &agrave; r&eacute;pondre aux
          exigences pos&eacute;es par le R&egrave;glement europ&eacute;en
          2016/679 du 27 avril 2016, sur la protection des personnes physiques
          &agrave; l&rsquo;&eacute;gard du traitement des donn&eacute;es
          &agrave; caract&egrave;re personnel (RGPD), en respectant les
          principes fondamentaux suivants&nbsp;(article 5 du RGPD) :{" "}
        </p>
        <ul>
          <li>
            Le principe de lic&eacute;it&eacute;, de loyaut&eacute; et de
            transparence&nbsp;: les donn&eacute;es collect&eacute;es le sont de
            mani&egrave;re licite, loyale et transparente.
          </li>
        </ul>
        <ul>
          <li>
            Le principe de limitation des finalit&eacute;s&nbsp;: les
            donn&eacute;es sont collect&eacute;es pour des objectifs
            d&eacute;termin&eacute;s, explicites et l&eacute;gitimes.
          </li>
        </ul>
        <ul>
          <li>
            Le principe de minimisation des donn&eacute;es&nbsp;: les
            donn&eacute;es doivent &ecirc;tre pertinentes et trait&eacute;es en
            ad&eacute;quation avec leur finalit&eacute;.
          </li>
        </ul>
        <ul>
          <li>
            Le principe d&rsquo;exactitude&nbsp;: les donn&eacute;es
            trait&eacute;es doivent &ecirc;tre exactes et tenues &agrave; jour.
          </li>
        </ul>
        <ul>
          <li>
            Le principe de limitation de la conservation&nbsp;: la dur&eacute;e
            de conservation des donn&eacute;es personnelles ne doit pas
            exc&eacute;der celle n&eacute;cessaire &agrave; la finalit&eacute;
            de leur traitement.
          </li>
        </ul>
        <ul>
          <li>
            Le principe d&rsquo;int&eacute;grit&eacute; et de
            confidentialit&eacute;&nbsp;: des mesures techniques ou
            organisationnelles appropri&eacute;es doivent &ecirc;tre mises en
            &oelig;uvre pour garantir une s&eacute;curit&eacute; des
            donn&eacute;es personnelles trait&eacute;es.
          </li>
        </ul>
        <p>
          De plus, en tant que Responsable de traitement, la
          Soci&eacute;t&eacute; a l&rsquo;obligation de prot&eacute;ger les
          donn&eacute;es personnelles en informant l&rsquo;Utilisateur de toute
          rectification ou suppression de ses donn&eacute;es ou si leur
          int&eacute;grit&eacute; ou confidentialit&eacute; est comprise.
        </p>

        <h2>COLLECTE DES DONN&Eacute;ES PERSONNELLES</h2>

        <p>
          Par donn&eacute;e &agrave; caract&egrave;re personnel, l&rsquo;on
          entend &laquo;&nbsp;
          <em>
            toute information se rapportant &agrave; une personne physique
            identifi&eacute;e ou identifiable
          </em>
          &nbsp;&raquo;, au sens de l&rsquo;article 4, 1) du RGPD.{" "}
        </p>
        <p>
          La collecte et le traitement de donn&eacute;es personnelles sont
          consentis par toute personne, lors de son utilisation de la
          pr&eacute;sente Application.{" "}
        </p>
        <p>
          Tout Utilisateur est amen&eacute; &agrave; communiquer &agrave; la
          Soci&eacute;t&eacute; des donn&eacute;es &agrave; caract&egrave;re
          personnel, dans le cadre des &eacute;tapes suivantes&nbsp;:
        </p>
        <ul>
          <li>La visite de l&rsquo;Application ;</li>
          <li>
            La connexion &agrave; son compte personnel sur
            l&rsquo;Application&nbsp;par l&rsquo;Utilisateur ;
          </li>
          <li>
            Le renseignement d&rsquo;un formulaire de saisie
            d&rsquo;informations&nbsp;;
          </li>
          <li>
            Le contact avec le Service client par tout moyen de communication
            mis &agrave; disposition.
          </li>
        </ul>
        <p>
          Les donn&eacute;es &agrave; caract&egrave;re personnel pouvant faire
          l&rsquo;objet d&rsquo;une collecte par la Soci&eacute;t&eacute; sont
          les suivantes&nbsp;:
        </p>
        <ul>
          <li>
            Les donn&eacute;es d&rsquo;identification de l&rsquo;Utilisateur,
            &agrave; savoir les chirurgiens : nom, pr&eacute;nom, adresse email,
            personnelle ou professionnelle.
          </li>
          <li>
            Les donn&eacute;es d&rsquo;identification du patient receveur,
            op&eacute;r&eacute; par l&rsquo;Utilisateur chirurgien&nbsp;: nom,
            pr&eacute;nom, date de naissance, sexe, num&eacute;ro unique du
            greffon&nbsp;; ainsi que les donn&eacute;es relatives &agrave; sa
            vie personnelle, son dossier m&eacute;dical.
          </li>
          <li>
            Les donn&eacute;es de connexion,&nbsp;g&eacute;olocalisation et de
            navigation&nbsp;: adresse IP, identifiants de connexions, cookies,
            terminaux de connexion, autorisation de la cam&eacute;ra.
          </li>
        </ul>
        <p>
          Les conversations t&eacute;l&eacute;phoniques entre un Utilisateur et
          un conseiller du Service Client de la Soci&eacute;t&eacute; peuvent
          &ecirc;tre enregistr&eacute;es, ce dont l&rsquo;Utilisateur en sera
          pr&eacute;alablement inform&eacute;.
        </p>
        <p>
          La Soci&eacute;t&eacute; est amen&eacute;e &agrave; collecter et
          traiter des donn&eacute;es dites &laquo;&nbsp;sensibles&nbsp;&raquo;,
          telles que celles concernant la sant&eacute; des personnes, sur le
          fondement du consentement explicite des personnes concern&eacute;es au
          traitement de ces donn&eacute;es &agrave; caract&egrave;re personnel
          pour une ou plusieurs finalit&eacute;s sp&eacute;cifiques, tel
          qu&rsquo;autoris&eacute; par l&rsquo;article 9, 2. a) du RGPD.{" "}
        </p>

        <h2>FINALITES DU TRAITEMENT DES DONNEES PERSONNELLES</h2>

        <p>
          La Soci&eacute;t&eacute; traite les donn&eacute;es collect&eacute;es
          de mani&egrave;re transparente et s&eacute;curis&eacute;e, aux fins
          d&eacute;taill&eacute;es ci-apr&egrave;s. Ces traitements reposent sur
          l&rsquo;une des bases juridiques l&eacute;galement pr&eacute;vues par
          &agrave; l&rsquo;article 6 du RGPD.
        </p>
        <h3>Gestion du compte de l&rsquo;Utilisateur</h3>
        <p>
          En raison de l&rsquo;ex&eacute;cution du contrat conclu entre la
          Soci&eacute;t&eacute; et l&rsquo;Utilisateur lors de
          l&rsquo;inscription sur l&rsquo;Application, via l&rsquo;acceptation
          des Conditions G&eacute;n&eacute;rales d&rsquo;Utilisation, la
          Soci&eacute;t&eacute; collecte et utilise des donn&eacute;es
          personnelles de l&rsquo;Utilisateur, permettant ainsi la gestion de
          son compte client sur la pr&eacute;sente Application.&nbsp;
        </p>
        <h3>
          Gestion administrative de la tra&ccedil;abilit&eacute; de produits
          implantables
        </h3>
        <p>
          Avec le consentement explicite et pr&eacute;alable du patient, la
          Soci&eacute;t&eacute; collecte et traite ses donn&eacute;es
          personnelles dans le cadre de la tra&ccedil;abilit&eacute; du produit,
          suite &agrave; son implantation de greffe osseuse par
          l&rsquo;Utilisateur chirurgien.{" "}
        </p>
        <h3>Gestion du Service Client</h3>
        <p>
          Afin d&rsquo;am&eacute;liorer la qualit&eacute; du service, la
          Soci&eacute;t&eacute; peut l&eacute;gitimement collecter et conserver
          des donn&eacute;es personnelles pour communiquer avec
          l&rsquo;Utilisateur sur ses r&eacute;clamations.{" "}
        </p>
        <h3>Gestion administrative et financi&egrave;re</h3>
        <p>
          En raison d&rsquo;un int&eacute;r&ecirc;t l&eacute;gitime de la
          Soci&eacute;t&eacute;, celle-ci peut &ecirc;tre tenue de communiquer
          certaines donn&eacute;es personnelles de l&rsquo;Utilisateur en cas de
          demandes l&eacute;gitimes d&rsquo;autorit&eacute;s publiques, afin de
          r&eacute;pondre &agrave; des exigences de s&eacute;curit&eacute;
          nationale, de lutte de pr&eacute;vention contre la fraude ou
          d&rsquo;application de la loi.
        </p>
        <h3>Autres cas</h3>
        <p>
          Dans le cadre d&rsquo;un traitement autre que ceux
          pr&eacute;c&eacute;demment d&eacute;taill&eacute;s,
          l&rsquo;Utilisateur en sera averti au pr&eacute;alable, par la
          Soci&eacute;t&eacute;.{" "}
        </p>

        <h2>DUREE DE CONSERVATION DES DONNEES</h2>

        <p>
          Les donn&eacute;es trait&eacute;es sont conserv&eacute;es par la
          Soci&eacute;t&eacute;, uniquement durant la p&eacute;riode
          n&eacute;cessaire &agrave; l&rsquo;accomplissement des
          finalit&eacute;s pr&eacute;c&eacute;demment d&eacute;crites, et
          n&eacute;cessaire &agrave; la bonne gestion de la relation avec
          l&rsquo;Utilisateur.{" "}
        </p>
        <table cellSpacing="0" cellPadding="0">
          <tbody>
            <tr>
              <td>
                <p>Cat&eacute;gories de donn&eacute;es personnelles</p>
              </td>
              <td>
                <p>Dur&eacute;e de conservation</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>
                    Donn&eacute;es du compte de l&rsquo;Utilisateur
                  </strong>
                </p>
              </td>
              <td>
                <p>
                  5 ans &agrave; compter de la fin de la relation commerciale
                  avec l&rsquo;Utilisateur (&agrave; partir de la
                  derni&egrave;re connexion au compte, du dernier appel au
                  service client, de l&rsquo;envoi d&rsquo;un courriel au
                  service client).
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Donn&eacute;es de sant&eacute; du patient</strong>
                </p>
              </td>
              <td>
                <p>30 ans apr&egrave;s l&rsquo;utilisation clinique</p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>Service Client</strong>
                </p>
              </td>
              <td>
                <p>
                  5 ans &agrave; compter de la cl&ocirc;ture de la demande
                  d&rsquo;assistance de l&rsquo;Utilisateur
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p>
                  <strong>
                    Pi&egrave;ce d&rsquo;identit&eacute; dans le cadre de
                    l&rsquo;exercice des droits d&rsquo;interrogations,
                    d&rsquo;acc&egrave;s, de rectification et d&rsquo;opposition
                  </strong>
                </p>
              </td>
              <td>
                <p>
                  1 an &agrave; compter de la date de r&eacute;ception par la
                  Soci&eacute;t&eacute;
                </p>
              </td>
            </tr>
          </tbody>
        </table>
        <p>
          Au terme de ces dur&eacute;es l&eacute;gales, les donn&eacute;es
          &agrave; caract&egrave;re personnel doivent &ecirc;tre en principe
          supprim&eacute;es. Toutefois, elles peuvent &eacute;galement
          &ecirc;tre archiv&eacute;es ou faire l&rsquo;objet d&rsquo;un
          processus d&rsquo;anonymisation, afin de rendre impossible
          l&rsquo;identification des personnes.{" "}
        </p>
        <p>
          Par cons&eacute;quent, elles ne seront plus consid&eacute;r&eacute;es
          comme &eacute;tant des donn&eacute;es &agrave; caract&egrave;re
          personnel et pourront &ecirc;tre conserv&eacute;es librement.{" "}
        </p>

        <h2>DESTINATAIRES DES DONN&Eacute;ES</h2>

        <p>
          La Soci&eacute;t&eacute; s&rsquo;assure que seules les personnes ayant
          besoin de traiter les donn&eacute;es pour l&rsquo;accomplissement de
          leurs obligations l&eacute;gales et contractuelles y aient
          acc&egrave;s.
        </p>
        <p>
          Les donn&eacute;es &agrave; caract&egrave;re personnel ainsi
          collect&eacute;es sont communiqu&eacute;es aux Utilisateurs
          chirurgiens, secr&eacute;taires m&eacute;dicales ou autre personnel
          m&eacute;dical, ainsi qu&rsquo;au service informatique, service
          Client, logistique interne ou encore la direction administrative et
          financi&egrave;re de la Soci&eacute;t&eacute;.
        </p>
        <p>
          Cependant, certains prestataires et sous-traitants de la
          Soci&eacute;t&eacute; peuvent &ecirc;tre destinataires de
          donn&eacute;es personnelles, si elles sont strictement
          n&eacute;cessaires &agrave; la r&eacute;alisation de leur prestation,
          ce qui est notamment le cas pour l&rsquo;h&eacute;bergement de
          l&rsquo;Application.
        </p>
        <p>
          A cet &eacute;gard, la Soci&eacute;t&eacute; s&rsquo;engage &agrave;
          faire appel uniquement &agrave; des sous-traitants pr&eacute;sentant
          des garanties suffisantes et respectant les engagements en
          mati&egrave;re de protection des donn&eacute;es personnelles.
        </p>
        <p>
          De plus, les op&eacute;rations avec un prestataire destinataire de
          donn&eacute;es personnelles font l&rsquo;objet d&rsquo;un contrat afin
          d&rsquo;assurer la protection des donn&eacute;es et du respect des
          droits des Utilisateurs.
        </p>
        <p>
          La Soci&eacute;t&eacute; peut &eacute;galement fournir des
          donn&eacute;es personnelles aux autorit&eacute;s de contr&ocirc;le
          telles que l&rsquo;administration fiscale et douani&egrave;re, la
          police et autres organes statutaires.{" "}
        </p>
        <p>
          Enfin, ces donn&eacute;es ne font pas l&rsquo;objet de transfert hors
          Union europ&eacute;enne.
        </p>

        <h2>
          MESURES DE SECURITE ET DE CONFIDENTIALIT&Eacute; DES DONN&Eacute;ES
        </h2>

        <p>
          La Soci&eacute;t&eacute; s&rsquo;engage &agrave; ne pas vendre, louer
          ni partager les informations personnellement identifiables des
          Utilisateurs de l&rsquo;Application avec des tiers, sauf raison
          l&eacute;gale contraignante (transmission &agrave; des services
          externes tels que les autorit&eacute;s de surveillance ou de poursuite
          p&eacute;nale).
        </p>
        <p>
          La Soci&eacute;t&eacute; s&rsquo;est par ailleurs efforc&eacute;e de
          mettre en &oelig;uvre toutes les pr&eacute;cautions raisonnables et
          n&eacute;cessaires &agrave; la pr&eacute;servation de la
          confidentialit&eacute; et de la s&eacute;curit&eacute; des
          donn&eacute;es &agrave; caract&egrave;re personnel trait&eacute;es,
          pour ainsi emp&ecirc;cher tout endommagement, d&eacute;formation ou
          destruction des donn&eacute;es.{" "}
        </p>
        <p>
          En application de l&rsquo;article 32 du RGPD, des mesures de
          s&eacute;curit&eacute; techniques et organisationnelles ont
          &eacute;t&eacute; instaur&eacute;es afin de prot&eacute;ger les
          donn&eacute;es contre toute intrusion malveillante, perte,
          destruction, alt&eacute;ration ou acc&egrave;s par des personnes non
          autoris&eacute;es, telles que&nbsp;:
        </p>
        <ul>
          <li>
            Le chiffrement des donn&eacute;es &agrave; caract&egrave;re
            personnel&nbsp;: notamment par le chiffrement syst&eacute;matique
            lors de l&rsquo;&eacute;change des donn&eacute;es entre
            l&rsquo;Utilisateur et l&rsquo;Application, via l&rsquo;utilisation
            du protocole de transmission HTTPS&nbsp;;
          </li>
          <li>
            La garantie de la confidentialit&eacute;,
            l&rsquo;int&eacute;grit&eacute;, la disponibilit&eacute; et de la
            r&eacute;silience des services de traitement&nbsp;;
          </li>
          <li>
            La disponibilit&eacute; et l&rsquo;acc&egrave;s des donn&eacute;es
            personnelles dans des d&eacute;lais appropri&eacute;s&nbsp;;
          </li>
          <li>
            Une proc&eacute;dure permettant d&rsquo;analyser et
            d&rsquo;&eacute;valuer l&rsquo;efficacit&eacute; de telles mesures
            prises pour assurer la s&eacute;curit&eacute; du traitement.
          </li>
          <li>
            Le stockage de l&rsquo;ensemble des donn&eacute;es dans un
            coffre-fort num&eacute;rique certifi&eacute; HADS (h&eacute;bergeur
            agr&eacute;&eacute; de donn&eacute;es de sant&eacute;)
          </li>
        </ul>
        <p>
          La Soci&eacute;t&eacute; engage &eacute;galement les Utilisateurs
          &agrave; faire preuve de prudence pour emp&ecirc;cher tout
          acc&egrave;s non autoris&eacute; &agrave; leurs donn&eacute;es
          personnelles.{" "}
        </p>

        <h2>DROITS DES UTILISATEURS</h2>

        <p>
          Conform&eacute;ment &agrave; la l&eacute;gislation en vigueur, toute
          personne dont les donn&eacute;es personnelles sont collect&eacute;es
          et trait&eacute;es par la Soci&eacute;t&eacute;,
          b&eacute;n&eacute;ficient de plusieurs droits :{" "}
        </p>
        <ul>
          <li>
            Le droit d&rsquo;acc&egrave;s&nbsp;: ce droit permet &agrave; tout
            Utilisateur d&rsquo;obtenir la confirmation que des donn&eacute;es
            personnelles le concernant sont en possession de la
            Soci&eacute;t&eacute; et de conna&icirc;tre lesquelles. La nature du
            traitement peut &eacute;galement &ecirc;tre expliqu&eacute;e. Une
            copie de l&rsquo;ensemble des informations le concernant
            peut-&ecirc;tre d&eacute;livr&eacute;e, &agrave; sa demande.
          </li>
        </ul>
        <ul>
          <li>
            Le droit &agrave; la portabilit&eacute; : la Soci&eacute;t&eacute; a
            l&rsquo;obligation de transmettre ces donn&eacute;es personnelles
            dans un format techniquement exploitable par son Utilisateur.{" "}
          </li>
        </ul>
        <ul>
          <li>
            Le droit de rectification&nbsp;: tout Utilisateur peut demander et
            obtenir la rectification, la correction de toute erreur figurant au
            sein de ses donn&eacute;es personnelles qui se
            r&eacute;v&egrave;leraient inexactes. Cette mise &agrave; jour
            s&rsquo;applique quel que soit le fondement du traitement
            concern&eacute;.{" "}
          </li>
        </ul>
        <ul>
          <li>
            Le droit &agrave; l&rsquo;effacement&nbsp;: tout Utilisateur a le
            droit de demander et d&rsquo;obtenir l&rsquo;effacement de certaines
            de ses donn&eacute;es personnelles, avant leur suppression &agrave;
            l&rsquo;expiration du d&eacute;lai de conservation initialement
            pr&eacute;vu, lorsque ces donn&eacute;es font l&rsquo;objet
            d&rsquo;un traitement reposant sur le consentement de
            l&rsquo;Utilisateur ou sur l&rsquo;int&eacute;r&ecirc;t
            l&eacute;gitime de la Soci&eacute;t&eacute; &agrave; condition de
            respecter un d&eacute;lai minimum de 30 ans apr&egrave;s
            l&rsquo;utilisation clinique du produit par l&rsquo;Utilisateur.
          </li>
        </ul>
        <ul>
          <li>
            Le droit &agrave; la limitation du traitement&nbsp;: tout
            Utilisateur peut demander &agrave; la Soci&eacute;t&eacute; de
            limiter ou d&rsquo;interrompre le traitement de ses donn&eacute;es
            personnelles, dans certaines circonstances.
          </li>
        </ul>
        <ul>
          <li>
            Le droit d&rsquo;opposition&nbsp;: l&rsquo;Utilisateur
            concern&eacute; par la collecte de ses donn&eacute;es a le droit de
            s&rsquo;opposer &agrave; tout moment &agrave; leur traitement, pour
            des raisons tenant &agrave; sa situation particuli&egrave;re et si
            ce traitement n&rsquo;est plus n&eacute;cessaire &agrave;
            l&rsquo;int&eacute;r&ecirc;t l&eacute;gitime de la
            Soci&eacute;t&eacute;.
          </li>
        </ul>
        <ul>
          <li>
            Le droit de d&eacute;terminer le sort des donn&eacute;es
            apr&egrave;s le d&eacute;c&egrave;s&nbsp;: en vertu de
            l&rsquo;article 40-1 de la Loi Informatique et Libert&eacute;s,
            toute personne peut donner des directives relatives &agrave; la
            conservation, &agrave; l&rsquo;effacement et &agrave; la
            communication de ses donn&eacute;es personnelles apr&egrave;s son
            d&eacute;c&egrave;s &agrave; condition de respecter un d&eacute;lai
            minimum de 30 ans apr&egrave;s l&rsquo;utilisation clinique du
            produit par l&rsquo;Utilisateur.{" "}
          </li>
        </ul>
        <p>
          En outre, la Soci&eacute;t&eacute; n&rsquo;a recours &agrave; aucun
          processus de traitement enti&egrave;rement automatis&eacute; pour
          prendre une d&eacute;cision et aucun profilage ne sera
          r&eacute;alis&eacute; sur la base des donn&eacute;es
          collect&eacute;es.
        </p>
        <p>
          Enfin, le consentement donn&eacute; par un Utilisateur ou un patient
          pour le traitement de ses donn&eacute;es personnelles n&rsquo;est pas
          d&eacute;finitif. Il peut le retirer &agrave; tout moment.
        </p>

        <h2>EXERCICE DES DROITS - CONTACT</h2>

        <p>
          Tout Client peut obtenir des informations ou exercer ces droits
          aupr&egrave;s du Responsable de traitement des donn&eacute;es
          personnelles de la Soci&eacute;t&eacute;&nbsp;:
        </p>
        <ul>
          <li>
            Par courrier &eacute;lectronique&nbsp;:{" "}
            <a href="mailto:contact-dpo@ost-dev.com">contact-dpo@ost-dev.com</a>
          </li>
          <li>
            Par courrier postal&nbsp;: OST D&eacute;veloppement, 15 Rue Georges
            Besse, 63017 Clermont-Ferrand Cedex 2
          </li>
        </ul>
        <p>
          La Soci&eacute;t&eacute; s&rsquo;engage &agrave; r&eacute;pondre
          &agrave; toute demande d&rsquo;un Utilisateur dans un d&eacute;lai
          raisonnable qui ne saurait d&eacute;passer un (1) mois &agrave;
          compter de la r&eacute;ception de ladite demande.{" "}
        </p>
        <p>
          Si l&rsquo;Utilisateur consid&egrave;re que la Soci&eacute;t&eacute;
          ne respecte pas ses obligations au regard de ses donn&eacute;es
          personnelles, il peut adresser une plainte ou d&eacute;poser une
          r&eacute;clamation aupr&egrave;s de l&rsquo;autorit&eacute;
          comp&eacute;tente&nbsp;:{" "}
        </p>
        <ul>
          <li>
            Par courrier postal&nbsp;: CNIL, 3 Place de Fontenoy, TSA 80715,
            75334 PARIS Cedex 07
          </li>
          <li>
            Via le site Internet&nbsp;: (voir les modalit&eacute;s sur{" "}
            <a href="https://www.cnil.fr/fr/agir">
              https://www.cnil.fr/fr/agir
            </a>
            )
          </li>
        </ul>
      </Content>
    </Page>
  );
}

export default PolitiqueDeConfidentialités;
