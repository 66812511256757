import React from "react";
import { size } from "lodash";
import { Redirect } from "react-router-dom";

function IsValidGraft({ currentGraft }) {
  if (!currentGraft || size(currentGraft) <= 0) {
    return <Redirect to="/" />;
  }
  return null;
}

export default IsValidGraft;
