import React from "react";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

import "./App.scss";

import store, { persistor } from "./redux/store";

import PrivateRoute from "./components/PrivateRoute";

// import Loading from "./pages/Loading";
import Login from "./pages/Login";
import Graft from "./pages/Graft";
import GraftFinal from "./pages/GraftFinal";
import PolitiqueDeConfidentialites from "./content/PolitiqueDeConfidentialites";
import CGU from "./content/CGU";
import MentionsLegales from "./content/MentionsLegales";

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Router>
          <Switch>
            {/* 
          <Route path="/" exact={true}>
            <Loading />
          </Route>*/}
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/content/politique-de-confidentialite" exact={true}>
              <PolitiqueDeConfidentialites />
            </Route>
            <Route
              path="/content/conditions-generales-utilisation"
              exact={true}
            >
              <CGU />
            </Route>
            <Route path="/content/mentions-legales" exact={true}>
              <MentionsLegales />
            </Route>
            <PrivateRoute path="/graft/final" exact={true}>
              <GraftFinal />
            </PrivateRoute>
            <PrivateRoute path="/">
              <Graft />
            </PrivateRoute>
          </Switch>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
