import React from "react";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components/macro";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { map, find, get } from "lodash";

import Title from "./Title";
import Button from "./Button";
import Input from "./Input";
import Checkbox from "./Checkbox";

import { setPrescriptionIndication } from "../redux/prescription";

const InputWrapper = styled.div`
  padding: 16px 0;
  border-top: 1px solid rgb(0, 75, 135, 0.15);
`;

const indicationsSchema = Yup.object()
  .shape({
    surgicalIndications: Yup.string(),
    extraIndication: Yup.string()
  })
  .test(
    "at-least-one-property",
    "indications manquantes",
    value => !!(value.surgicalIndications || value.extraIndication)
  );

function GraftStep5({ indications, savedIndication }) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div>
      <Title
        css={`
          color: var(--main-color);
        `}
      >
        Indications chirurgicales
      </Title>
      <Formik
        initialValues={{
          surgicalIndications:
            savedIndication && savedIndication.code !== "12"
              ? savedIndication.code
              : "",
          extraIndication:
            savedIndication && savedIndication.code === "12"
              ? savedIndication.description
              : ""
        }}
        validationSchema={indicationsSchema}
        onSubmit={values => {
          const indic = find(
            indications,
            i => i.code === values.surgicalIndications
          );
          dispatch(
            setPrescriptionIndication(
              values.surgicalIndications
                ? { code: indic.code, description: indic.name }
                : { code: "12", description: values.extraIndication }
            )
          );
          history.push("/graft/6");
        }}
      >
        {({ isValid, handleSubmit, values, setFieldValue }) => {
          return (
            <form onSubmit={handleSubmit}>
              {map(indications, s => {
                return (
                  <InputWrapper key={s.code}>
                    <Field
                      noMargin
                      type="radio"
                      name="surgicalIndications"
                      id={`indications-${s.code}`}
                      component={Checkbox}
                      value={s.code}
                      checked={values.surgicalIndications === s.code}
                      label={s.name}
                      onChange={event => {
                        setFieldValue("extraIndication", "");
                        setFieldValue(
                          "surgicalIndications",
                          event.target.value
                        );
                      }}
                    />
                  </InputWrapper>
                );
              })}

              <Field
                css={`
                  margin-top: 30px;
                `}
                name="extraIndication"
                type="text"
                component={Input}
                placeholder="Si autres, précisez …"
                onChange={event => {
                  setFieldValue("surgicalIndications", "");
                  setFieldValue("extraIndication", event.target.value);
                }}
              />

              <Button type="submit" disabled={!isValid}>
                Suivant
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

const mapStateToPros = state => ({
  savedIndication: get(state, "prescription.surgicalIndication", ""),
  indications: state.indications
});

export default connect(mapStateToPros)(GraftStep5);
