import React from "react";
import styled from "styled-components/macro";
import { map } from "lodash";

import { Error } from "./Input";
import Label from "./Label";

const CustomSelect = styled.select`
  appearance: none;
  padding: 10px 5px;
  background: rgb(239, 239, 239, 0.4);
  border: 0;
  border-radius: 10px;
  width: 100%;
  display: block;
  height: 58px;
  text-indent: 5px;
`;

const SelectField = ({
  options,
  field,
  form: { errors, touched, setFieldValue },
  onChange,
  label
}) => {
  return (
    <>
      {label ? (
        <Label error={touched[field.name] && errors[field.name]}>{label}</Label>
      ) : null}
      <CustomSelect
        name={field.name}
        onChange={e => {
          const val = e.target.value;
          if (onChange) {
            onChange(val);
          } else {
            setFieldValue(field.name, val);
          }
        }}
        onBlur={field.onBlur}
        value={field.value}
      >
        {map(options, o => (
          <option key={o.value} value={o.value}>
            {o.label}
          </option>
        ))}
      </CustomSelect>
      {touched[field.name] && errors[field.name] && (
        <Error>{errors[field.name]}</Error>
      )}
    </>
  );
};

export default SelectField;
