import React from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro"; //eslint-disable-line
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { omit } from "lodash";

import Title from "./Title";
import Input from "./Input";
import Button from "./Button";
import Select from "./Select";

import { setPatientInfos } from "../redux/prescription";

const interventionSchema = Yup.object().shape({
  date: Yup.string()
    .matches(
      /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[012])\/(\d{4})$/,
      "Format incorrect: JJ/MM/AAAA"
    )
    .required("Requis"),
  firstName: Yup.string(),
  lastName: Yup.string().required("Requis"),
  birthDate: Yup.string().matches(
    /^(0[1-9]|[12]\d|3[01])\/(0[1-9]|1[012])\/(\d{4})$/,
    "Format incorrect: JJ/MM/AAAA"
  ),
  gender: Yup.string()
});

function GraftStep3({ patient, date }) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div>
      <Formik
        isInitialValid={true}
        initialValues={{
          date: date,
          ...patient
        }}
        validationSchema={interventionSchema}
        onSubmit={values => {
          dispatch(
            setPatientInfos({
              interventionDate: values.date,
              patient: omit(values, "date")
            })
          );
          history.push("/graft/4");
        }}
      >
        {({ handleSubmit, isValid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                component={Input}
                autoComplete="off"
                name="date"
                type="date"
                label="Date de l'intervention"
                css={`
                  text-align: center;
                `}
              />

              <Title
                css={`
                  color: var(--main-color);
                `}
              >
                Receveur
              </Title>
              <Field
                component={Input}
                autoComplete="off"
                name="lastName"
                type="text"
                label="Nom"
              />
              <Field
                component={Input}
                autoComplete="off"
                name="firstName"
                type="text"
                label="Prénom"
              />
              <Field
                component={Input}
                autoComplete="off"
                name="birthDate"
                type="date"
                label="Date de naissance"
              />
              <Field
                component={Select}
                name="gender"
                label="Genre"
                options={[
                  {
                    label: "",
                    value: ""
                  },
                  {
                    label: "Masculin",
                    value: "Masculin"
                  },
                  {
                    label: "Féminin",
                    value: "Féminin"
                  }
                ]}
              />

              <Button type="submit" disabled={!isValid}>
                Suivant
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

const mapStateToProps = state => ({
  patient: state.prescription.patient,
  date: state.prescription.interventionDate
});

export default connect(mapStateToProps)(GraftStep3);
