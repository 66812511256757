import React from "react";
import ReactSVG from "react-svg";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";

import Menu from "../components/Menu";

import backIcon from "../img/back.svg";

import { toggleMenu } from "../redux/ui";

const PageStyled = styled.div`
  height: 100%;
  padding: 80px 20px 20px;
  position: relative;
`;

const Header = styled.div`
  position: absolute;
  top: 30px;
  left: 20px;
  width: calc(100% - 40px);
  display: flex;
  align-items: center;
  justify-content: space-between;

  svg {
    display: flex;
  }

  button {
  }
`;

const BackButton = styled.button`
  margin: 0;
  padding: 0;
  background: 0;
  border: 0;
`;

function Page({
  children,
  className,
  withNav = true,
  withBack = true,
  menuOpen
}) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <PageStyled className={className}>
      <Header>
        {withBack ? (
          <BackButton
            onClick={() => {
              if (menuOpen) {
                dispatch(toggleMenu(false));
              }
              history.go(-1);
            }}
          >
            <ReactSVG src={backIcon} />
          </BackButton>
        ) : null}
        {withNav ? <Menu /> : null}
      </Header>
      <div
        css={`
          min-height: 100%;
          max-width: 640px;
          margin: auto;
        `}
      >
        {children}
      </div>
    </PageStyled>
  );
}

const mapStateToProps = state => ({
  menuOpen: state.ui.menu
});

export default connect(mapStateToProps)(Page);
