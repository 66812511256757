import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import styled from "styled-components/macro";
import ReactSVG from "react-svg";
import QueryString from "query-string";

import Page from "../layouts/Page";
import Button from "../components/Button";

import checkIcon from "../img/check.svg";

const Wrapper = styled(Page)`
  background: #00a3e0;
  background: var(--main-color);
  color: #fff;
  text-align: center;
  height: 100vh;
  > div {
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
  margin-top: 20px;
  margin-bottom: 30px;
`;

function GraftFinal() {
  const history = useHistory();
  const location = useLocation();
  const { code } = QueryString.parse(location.search);

  return (
    <Wrapper withNav={false} withBack={false}>
      <div
        css={`
          margin: auto;
        `}
      >
        <ReactSVG src={checkIcon} />
        <Title>
          Code Greffon <br />
          {code && typeof code === "string" ? code : ""}
        </Title>

        <div>
          Votre saisie est enregistrée,vous aller recevoir un email de
          confirmation à l’adresse renseignée. Pour toute modification, veuillez
          contacter votre service client.
        </div>
      </div>
      <Button
        onClick={() => {
          history.push("/graft/1");
        }}
        css={`
          margin-top: auto;
          background-color: #fff;
          color: var(--main-color);
          position: sticky;
          bottom: 0;
        `}
      >
        Nouveau Greffon
      </Button>
    </Wrapper>
  );
}

export default GraftFinal;
