function download(fileEntry, uri, readBinaryData) {
  var fileTransfer = new window.FileTransfer();
  var fileURL = fileEntry.toURL();

  fileTransfer.download(
    encodeURI(uri),
    fileURL,
    function(entry) {
      window.cordova.plugins.fileOpener2.open(
        entry.toURL(),
        "application/pdf",
        {
          error: function(e) {
            console.log(
              "Error status: " + e.status + " - Error message: " + e.message
            );
          },
          success: function() {
            console.log("file opened successfully");
          }
        }
      );
    },
    function(error) {
      alert("download error source " + error.source);
      alert("download error target " + error.target);
      alert("upload error code" + error.code);
    },
    null,
    {}
  );
}

export default function downloadFile(url, fileName = "download") {
  window.requestFileSystem(
    window.TEMPORARY,
    5 * 1024 * 1024,
    function(fs) {
      fs.root.getFile(
        `${fileName}.pdf`,
        { create: true, exclusive: false },
        function(fileEntry) {
          download(fileEntry, url, true);
        },
        function() {
          alert("onErrorCreateFile");
        }
      );
    },
    function() {
      alert("onErrorLoadFs");
    }
  );
}
