import React, { useRef } from "react";
import ReactSVG from "react-svg";
import { useHistory } from "react-router-dom";
import { PURGE } from "redux-persist";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { useClickAway } from "react-use";

import menuIcon from "../img/menu.svg";
import closeIcon from "../img/close.svg";

import { resetPrescription } from "../redux/prescription";
import { toggleMenu } from "../redux/ui";
import { isAuthenticatedSelector } from "../redux/selectors";

const NavMenuButton = styled.button`
  color: #fff;
  padding: 20px;
  display: block;
  width: 100%;
  text-align: left;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  margin: 0;
  background: transparent;
  text-transform: uppercase;
  font-size: 14px;
  &:hover {
    background: #004b87;
    background: var(--main-color-dark);
  }
`;

const PractitionerCode = styled(NavMenuButton)`
  margin-top: auto;
  &:hover {
    background: transparent;
  }
`;

const NavMenu = styled.div`
  position: fixed;
  background: #00a3e0;
  background: var(--main-color);
  right: 0;
  top: 0;
  min-width: 320px;
  max-width: calc(100% - 100px);
  height: 100%;
  z-index: 10;
  transition: transform 250ms linear;
  box-shadow: -4px 0px 5px 0px rgba(0, 0, 0, 0.45);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`;

const MenuButton = styled.button`
  z-index: 1;
  margin: 0;
  padding: 0;
  background: 0;
  border: 0;
`;

const Nav = styled.div`
  margin-left: auto;

  ${NavMenu} {
    transform: ${props =>
      props.open ? "transform: translate(0)" : "translateX(120%)"};
  }
`;

function Menu({ menuOpen, isAuthenticated, practitionerCode }) {
  const NavRef = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const clickAction = () => {
    if (menuOpen) {
      dispatch(toggleMenu(false));
    }
  };

  useClickAway(NavRef, () => {
    dispatch(toggleMenu(false));
  });

  return (
    <Nav open={menuOpen} ref={NavRef}>
      <MenuButton onClick={() => dispatch(toggleMenu(!menuOpen))}>
        <ReactSVG src={menuIcon} />
      </MenuButton>
      <NavMenu>
        <NavMenuButton
          onClick={() => clickAction()}
          css={`
            display: flex;
            align-items: center;
          `}
        >
          <ReactSVG
            src={closeIcon}
            css={`
              margin-right: 10px;
            `}
          />{" "}
          Fermer le menu
        </NavMenuButton>
        {isAuthenticated ? (
          <NavMenuButton
            onClick={() => {
              clickAction();
              history.push("/");
            }}
          >
            Accueil
          </NavMenuButton>
        ) : null}
        {isAuthenticated ? (
          <NavMenuButton
            onClick={() => {
              clickAction();
              dispatch(resetPrescription());
              history.push("/");
            }}
          >
            Nouveau Greffon
          </NavMenuButton>
        ) : null}
        <NavMenuButton
          onClick={() => {
            clickAction();
            history.push("/content/conditions-generales-utilisation");
          }}
        >
          Conditions générales d'utilisation
        </NavMenuButton>
        <NavMenuButton
          onClick={() => {
            clickAction();
            history.push("/content/politique-de-confidentialite");
          }}
        >
          Politiques de confidentialité
        </NavMenuButton>
        <NavMenuButton
          onClick={() => {
            clickAction();
            history.push("/content/mentions-legales");
          }}
        >
          Mentions légales
        </NavMenuButton>
        {isAuthenticated ? (
          <NavMenuButton
            onClick={() => {
              dispatch({
                type: PURGE,
                key: "root",
                result: () => null
              });

              clickAction();
              history.push("/login");
            }}
          >
            Déconnexion
          </NavMenuButton>
        ) : null}
        {practitionerCode ? (
          <PractitionerCode as="div">
            code client: <strong>{practitionerCode}</strong>
          </PractitionerCode>
        ) : null}
      </NavMenu>
    </Nav>
  );
}

const mapStateToProps = state => ({
  menuOpen: state.ui.menu,
  isAuthenticated: isAuthenticatedSelector(state),
  practitionerCode: state.practitioner.practitionerCode
});

export default connect(mapStateToProps)(Menu);
