import React from "react";
import styled from "styled-components/macro";

const Wrapper = styled.div`
  background: linear-gradient(
    90deg,
    rgba(50, 137, 206, 0.4) 0%,
    rgba(50, 137, 206, 0.4) 100%
  );
  height: 6px;
  border-radius: 6px;
  margin-bottom: 30px;
  position: relative;
  overflow: hidden;
`;
const Filler = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(
    90deg,
    rgba(50, 137, 206, 1) 0%,
    rgba(50, 137, 206, 1) 100%
  );
  height: 100%;
  width: ${props => props.percentage}%;
`;

function Progress({ className, percentage = 0 }) {
  return (
    <Wrapper className={className}>
      <Filler percentage={percentage} />
    </Wrapper>
  );
}

export default Progress;
