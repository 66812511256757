import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Formik, Field } from "formik";
import styled from "styled-components/macro";
import ReactSVG from "react-svg";
import * as Yup from "yup";
import { trim, split, get, join, replace } from "lodash";
import { useDispatch } from "react-redux";

import Title from "./Title";
import Button from "./Button";
import Input from "./Input";
import Scanner from "./Scanner";
import CodeGreffon from "../content/CodeGreffon";

import infoIcon from "../img/infos.svg";
import closeIcon from "../img/close.svg";

import { sendGrafts } from "../redux/prescription";

const Infos = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: underline;
  margin-top: 55px;
  margin-bottom: 90px;
  text-align: center;

  div {
    display: flex;
  }
  svg {
    margin-right: 5px;
    width: 22px;
    height: 22px;
    opacity: 0.46;
  }
`;

const MoreInfos = styled.div`
  color: #fff;
  position: fixed;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: #00a3e0;
  background: var(--main-color);
  padding: 90px 20px 20px 20px;
  transform: ${props => (props.open ? "translateY(0)" : "translateY(-100%)")};
  transition: transform 250ms ease-in-out;
  overflow: hidden;
  button {
    position: absolute;
    right: 30px;
    top: 30px;
    border: 0;
    background: transparent;
  }
`;

const NewGraftSchema = Yup.object().shape({
  graftCodes: Yup.string()
    .trim()
    .required("Requis")
});

function GraftStep1({ currentGraft = [] }) {
  const history = useHistory();
  const dispatch = useDispatch();
  const [infosOpen, setInfosOpen] = useState(false);

  return (
    <div>
      <Formik
        initialValues={{ graftCodes: join(currentGraft, ", ") }}
        validationSchema={NewGraftSchema}
        onSubmit={async (values, { setErrors }) => {
          const data = await dispatch(
            sendGrafts(split(trim(replace(values.graftCodes, " ", "")), ","))
          );
          const errors = data.grafts.filter(g => g.error);

          if (errors.length <= 0) {
            history.push("/graft/2");
          } else {
            setErrors({
              graftCodes: errors.map(g => `${g.code} : ${g.error}`).join("\n")
            });
          }
        }}
      >
        {({ handleSubmit, isValid, setFieldValue, values }) => {
          return (
            <form onSubmit={handleSubmit}>
              {get(window, "plugins.GMVBarcodeScanner") ? (
                <>
                  <Scanner
                    handleDetect={result =>
                      setFieldValue(
                        "graftCodes",
                        `${values.graftCodes}${
                          values.graftCodes ? "," : ""
                        }${result}`
                      )
                    }
                  />

                  <div
                    css={`
                      margin: 25px auto;
                      text-align: center;
                    `}
                  >
                    OU
                  </div>
                </>
              ) : null}

              <Field
                component={Input}
                name="graftCodes"
                type="text"
                label={
                  <div>
                    Saisissez le code greffon.
                    <br />
                    <small
                      css={`
                        color: grey;
                      `}
                    >
                      Si vous avez utilisé plusieurs greffons lors de
                      l'intervention, notez ici les codes greffons séparés par
                      une virgule.
                    </small>
                  </div>
                }
                css={`
                  text-align: center;
                `}
              />
              <Infos onClick={() => setInfosOpen(true)}>
                <ReactSVG src={infoIcon} />
                Où trouver le code greffon ?
              </Infos>
              <Button type="submit" disabled={!isValid}>
                Validation
              </Button>
            </form>
          );
        }}
      </Formik>

      <MoreInfos open={infosOpen}>
        <div
          css={`
            overflow-x: auto;
            height: 100%;
          `}
        >
          <button onClick={() => setInfosOpen(false)}>
            <ReactSVG src={closeIcon} />
          </button>
          <Title>Où trouver le code Greffon ?</Title>
          <CodeGreffon />
        </div>
      </MoreInfos>
    </div>
  );
}

export default GraftStep1;
