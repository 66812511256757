import { createSlice } from "redux-starter-kit";
import { isBoolean } from "util";

const ui = createSlice({
  name: "ui",
  initialState: {
    menu: false
  },
  reducers: {
    toggleMenu: (state, action) => {
      state.menu = isBoolean(action.payload) ? action.payload : !state.menu;
    }
  }
});

export const { toggleMenu } = ui.actions;
export default ui.reducer;
