import fetch from "../api";
import { createSlice } from "redux-starter-kit";

const surgeons = createSlice({
  name: "surgeons",
  initialState: [],
  reducers: {
    setSurgeons: (state, action) => action.payload || [],
    addSurgeon: (state, action) => {
      if (action.payload) {
        state.push(action.payload);
      }
    },
    updateSurgeon: (state, action) => {
      return state.map(surgeon => {
        if (surgeon.code === action.payload.code) {
          return {
            ...surgeon,
            ...action.payload
          };
        }
        return surgeon;
      });
    }
  }
});

export const { setSurgeons, addSurgeon, updateSurgeon } = surgeons.actions;
export default surgeons.reducer;

export const createSurgeon = name => async (dispatch, getState) => {
  try {
    const {
      practitioner: { token, practitionerCode },
      prescription: { code }
    } = getState();

    const data = await fetch({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE}/api/surgeon`,
      data: {
        practitionerCode,
        name,
        graft: code
      },
      withCredentials: true,
      headers: { Authorization: "bearer " + token }
    });

    dispatch(addSurgeon(data));

    return data;
  } catch (error) {
    throw error;
  }
};

export const editSurgeon = ({ surgeonCode, name }) => async (
  dispatch,
  getState
) => {
  try {
    const {
      practitioner: { token },
      prescription: { code }
    } = getState();

    const data = await fetch({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE}/api/surgeon/${surgeonCode}`,
      data: {
        name,
        graft: code
      },
      withCredentials: true,
      headers: { Authorization: "bearer " + token }
    });

    dispatch(updateSurgeon(data));

    return data;
  } catch (error) {
    throw error;
  }
};
