import styled from "styled-components/macro";

const Button = styled.button`
  background: #00a3e0;
  background: var(--main-color);
  cursor: pointer;
  outline: none;
  transition: all 0.2s linear;
  border: 1px solid #fff;
  border-radius: 50px;
  display: block;
  margin: 20px 0;
  color: #fff;
  font-weight: 500;
  padding: 15px 30px;
  width: 100%;
  &:hover {
    background: #004b87;
    background: var(--main-color-dark);
    border-color: var(--main-color-dark);
  }
  &:disabled {
    opacity: 0.5;
  }

  svg {
    display: flex;
    margin: auto;
  }
`;

export const ButtonOutline = styled(Button)`
  background: transparent;
  color: var(--main-color);
  border-color: var(--main-color);
  border-width: 2px;
`;

export default Button;
