import { createSlice } from "redux-starter-kit";

const indications = createSlice({
  name: "indications",
  initialState: [],
  reducers: {
    setIndications: (state, action) => action.payload || []
  }
});

export const { setIndications } = indications.actions;
export default indications.reducer;
