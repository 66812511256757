import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";

import { isAuthenticatedSelector } from "../redux/selectors";

function PrivateRoute({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isAuthenticated === true ? children : <Redirect to="/login" />;
      }}
    />
  );
}

const mapStateToProps = state => ({
  isAuthenticated: isAuthenticatedSelector(state)
});

export default connect(mapStateToProps)(PrivateRoute);
