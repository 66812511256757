import axios from "axios";
import { get } from "lodash";
import { PURGE } from "redux-persist";
import * as Sentry from "@sentry/browser";
import history from "./history";
import store from "./redux/store";

export const getToken = async practitionerCode => {
  try {
    const { data } = await axios({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE}/api/tokens`,
      data: {
        practitionerCode
      }
    });

    return data;
  } catch (error) {
    throw error;
  }
};

export default async config => {
  try {
    const { data } = await axios(config);
    return data;
  } catch (error) {
    if (get(error, "response.data.message") === "Expired JWT Token") {
      store.dispatch({
        type: PURGE,
        key: "root",
        result: () => null
      });
      history.push("/");
    } else {
      Sentry.withScope(scope => {
        scope.setExtra("state", store.getState());
        Sentry.captureException(error);
      });
    }
    throw error;
  }
};
