import { createSlice } from "redux-starter-kit";
import { get, map, omit } from "lodash";

import fetch from "../api";
import { setGrafts } from "./grafts";
import { setSurgeons } from "./surgeons";
import { setIndications } from "./indications";

const initialState = {
  code: [],
  interventionDate: "",
  patient: {
    firstName: "",
    lastName: "",
    birthDate: "",
    gender: ""
  },
  surgeon: "",
  surgicalIndication: "",
  comments: "",
  recipients: ""
};

const prescription = createSlice({
  name: "prescription",
  initialState: initialState,
  reducers: {
    setPrescriptionCode: (state, action) => {
      state.code = action.payload;
    },
    setPatientInfos: (state, action) => {
      state.interventionDate = action.payload.interventionDate;
      state.patient = action.payload.patient;
    },
    setSurgeon: (state, action) => {
      if (action.payload.code && action.payload.name) {
        state.surgeon = action.payload;
      }
    },
    setPrescriptionIndication: (state, action) => {
      state.surgicalIndication = action.payload;
    },
    setComment: (state, action) => {
      state.comments = action.payload;
    },
    setRecipients: (state, action) => {
      state.recipients = action.payload;
    },
    resetPrescription: () => initialState
  }
});

export const {
  setPrescriptionCode,
  setPatientInfos,
  setSurgeon,
  setPrescriptionIndication,
  setComment,
  setRecipients,
  resetPrescription
} = prescription.actions;

export default prescription.reducer;

export const sendGrafts = (graftCodes = []) => async (dispatch, getState) => {
  try {
    const {
      practitioner: { token, practitionerCode }
    } = getState();

    const data = await fetch({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE}/api/graft`,
      data: {
        practitionerCode,
        graftCodes
      },
      withCredentials: true,
      headers: { Authorization: "bearer " + token }
    });

    dispatch(setGrafts(data.grafts));
    dispatch(setSurgeons(data.surgeons));
    dispatch(setIndications(data.surgicalIndications));
    dispatch(setPrescriptionCode(map(get(data, "grafts"), g => g.code)));

    return data;
  } catch (error) {
    throw error;
  }
};

export const sendPrescription = () => async (dispatch, getState) => {
  try {
    const {
      prescription,
      practitioner: { email, token }
    } = getState();
    await fetch({
      method: "POST",
      url: `${process.env.REACT_APP_API_BASE}/api/graft/medical_prescription`,
      data: {
        email,
        recipients: prescription.recipients.replace(/\s/g, ""),
        grafts: map(prescription.code, code => {
          return {
            ...omit(prescription, "recipients"),
            code
          };
        })
      },
      withCredentials: true,
      headers: { Authorization: "bearer " + token }
    });

    dispatch(resetPrescription());
  } catch (error) {
    throw error;
  }
};
