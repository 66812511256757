import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import ReactSVG from "react-svg";
import Modal from "react-modal";
import { get, map, find } from "lodash";

import Title from "./Title";
import Select from "./Select";
import Button, { ButtonOutline } from "./Button";
import Input from "./Input";

import plusIcon from "../img/plus.svg";
import closeIcon from "../img/close.svg";
import editIcon from "../img/edit.svg";

import {
  createSurgeon,
  editSurgeon as editSurgeonAction
} from "../redux/surgeons";
import { setSurgeon } from "../redux/prescription";

const ExtraButton = styled.div`
  display: flex;
  align-items: center;
  color: var(--main-color);
  font-weight: 600;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-right: 20px;
  cursor: pointer;
  & + & {
    margin-right: 0;
  }

  svg {
    display: flex;
    align-items: center;
    width: 42px;
    height: 42px;
    margin-right: 10px;
  }
`;

const CloseModal = styled.div`
  display: flex;
  justify-content: flex-end;
  svg,
  path {
    fill: #00a3e0;
    fill: var(--main-color);
  }
`;

const surgeonSchema = Yup.object().shape({
  surgeon: Yup.string().required("Requis")
});

const newSurgeonSchema = Yup.object().shape({
  firstname: Yup.string().required("Requis"),
  lastname: Yup.string().required("Requis")
});

function GraftStep3({ prescription, surgeons }) {
  const history = useHistory();
  const dispatch = useDispatch();

  const [addNewSurgeon, SetAddNewSurgeon] = useState(false);
  const [editSurgeon, SetEditSurgeon] = useState(false);

  return (
    <div>
      <Formik
        initialValues={{
          surgeon: get(prescription, "surgeon.code", "")
        }}
        validateOnMount={true}
        validationSchema={surgeonSchema}
        onSubmit={(values, { setFieldError }) => {
          const surgeon = find(
            surgeons,
            s => s.code === values.surgeon || s.code === values.surgeon.value
          );
          if (surgeon) {
            dispatch(setSurgeon(surgeon));
            history.push("/graft/5");
          } else {
            setFieldError("surgeon", "Chirurgien introuvable");
          }
        }}
      >
        {({ handleSubmit, isValid, values: parentValues }) => {
          return (
            <>
              <form onSubmit={handleSubmit}>
                <Title
                  css={`
                    color: var(--main-color);
                  `}
                >
                  Chirurgien implanteur
                </Title>

                <Field
                  component={Select}
                  name="surgeon"
                  options={[
                    { label: "", value: "" },
                    ...map(surgeons, s => ({
                      label: s.name,
                      value: s.code
                    }))
                  ]}
                />
                <Button type="submit" disabled={!isValid}>
                  Validation
                </Button>
                <div
                  css={`
                    display: flex;
                    justify-content: space-between;
                  `}
                >
                  <ExtraButton
                    onClick={() => {
                      SetAddNewSurgeon(!addNewSurgeon);
                    }}
                  >
                    <ReactSVG src={plusIcon} />
                    Ajouter un chirurgien
                  </ExtraButton>

                  {parentValues.surgeon ? (
                    <ExtraButton
                      type="button"
                      onClick={() => {
                        SetEditSurgeon(!editSurgeon);
                      }}
                    >
                      <ReactSVG src={editIcon} />
                      Modifier le nom du chirurgien
                    </ExtraButton>
                  ) : null}
                </div>
              </form>
              <Modal isOpen={editSurgeon} ariaHideApp={false}>
                <CloseModal onClick={() => SetEditSurgeon(false)}>
                  <ReactSVG src={closeIcon} />
                </CloseModal>
                <Formik
                  initialValues={{
                    name: get(
                      find(surgeons, s => s.code === parentValues.surgeon),
                      "name",
                      ""
                    )
                  }}
                  onSubmit={async values => {
                    await dispatch(
                      editSurgeonAction({
                        surgeonCode: parentValues.surgeon,
                        name: values.name
                      })
                    );
                    SetEditSurgeon(false);
                  }}
                >
                  {({ handleSubmit, isValid, values }) => {
                    return (
                      <form onSubmit={handleSubmit}>
                        <Title>Modifier Chirurgien</Title>
                        <Field
                          component={Input}
                          type="text"
                          name="name"
                          placeholder="Nom"
                        />

                        <Button type="submit" disabled={!isValid}>
                          Modifier
                        </Button>
                        <ButtonOutline
                          type="button"
                          onClick={() => SetEditSurgeon(false)}
                        >
                          Annuler
                        </ButtonOutline>
                      </form>
                    );
                  }}
                </Formik>
              </Modal>
            </>
          );
        }}
      </Formik>

      {addNewSurgeon ? (
        <Formik
          initialValues={{
            firstname: "",
            lastname: ""
          }}
          validationSchema={newSurgeonSchema}
          onSubmit={async values => {
            const data = await dispatch(
              createSurgeon(`${values.firstname} ${values.lastname}`)
            );
            dispatch(setSurgeon(data));
            SetAddNewSurgeon(false);
          }}
        >
          {({ handleSubmit, isValid }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Field
                  component={Input}
                  type="text"
                  name="lastname"
                  placeholder="Nom"
                />
                <Field
                  component={Input}
                  type="text"
                  name="firstname"
                  placeholder="Prénom"
                />

                <Button type="submit" disabled={!isValid}>
                  Ajouter
                </Button>
              </form>
            );
          }}
        </Formik>
      ) : null}
    </div>
  );
}

const mapStateToProps = state => ({
  prescription: state.prescription,
  surgeons: state.surgeons
});

export default connect(mapStateToProps)(GraftStep3);
