import { createSelector } from "redux-starter-kit";
import { head, map } from "lodash";

export const isAuthenticatedSelector = createSelector(
  state => state.practitioner,
  ({ email, practitionerCode, token }) => {
    if (email && practitionerCode && token) {
      return true;
    }
    return false;
  }
);

export const currentGraftSelector = createSelector(
  state => state.prescription,
  ({ grafts }) => {
    return head(grafts);
  }
);
export const GraftCodesSelector = createSelector(
  state => state.prescription,
  ({ grafts }) => {
    return map(grafts, g => g.code);
  }
);
