import React from "react";
import { useHistory, Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import styled from "styled-components/macro"; //eslint-disable-line
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { join, get } from "lodash";

import Title from "./Title";
import Button from "./Button";
import Input from "./Input";

import {
  setComment,
  setRecipients,
  sendPrescription
} from "../redux/prescription";
import Checkbox from "./Checkbox";

const isEmail = value => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

const indicationsSchema = Yup.object().shape({
  comments: Yup.string(),
  recipients: Yup.string().test(
    "recipients",
    "adresse email invalide",
    value => {
      if (!value) return true;

      return value && value.split(",").every(isEmail);
    }
  ),

  conditions: Yup.bool().oneOf([true], "Requis")
});

function GraftStep5({ comments, recipients, currentGraft }) {
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <div>
      <Title
        css={`
          color: var(--main-color);
          text-align: center;
          margin-bottom: 40px;
        `}
      >
        Complications immédiates éventuelles, si retour greffon, motif du retour
        ou autres observations.
      </Title>
      <Formik
        initialValues={{
          comments: comments,
          recipients: recipients,
          conditions: false
        }}
        validationSchema={indicationsSchema}
        onSubmit={async values => {
          try {
            dispatch(setComment(values.comments));
            dispatch(setRecipients(values.recipients));
            await dispatch(sendPrescription());
            history.push(`/graft/final?code=${join(currentGraft, ",")}`);
          } catch (error) {
            const message = get(error, "response.data.error", "erreur");
            alert(message);
          }
        }}
      >
        {({ isValid, handleSubmit }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Field
                name="comments"
                type="textarea"
                component={Input}
                placeholder="…"
              />
              <Field
                name="recipients"
                type="text"
                component={Input}
                label={`Si vous souhaitez recevoir le document sur d'autres adresses emails, merci de les renseigner ci-dessous séparées par des ','`}
              />
              <Field
                name="conditions"
                type="checkbox"
                component={Checkbox}
                label={
                  <span>
                    J’ai lu et j’accepte les{" "}
                    <Link to="/content/conditions-generales-utilisation">
                      conditions générales d’utilisation
                    </Link>
                  </span>
                }
              />

              <Button
                type="submit"
                disabled={!isValid}
                css={`
                  margin-top: 65px;
                `}
              >
                Valider la prescription
              </Button>
            </form>
          );
        }}
      </Formik>
    </div>
  );
}

const mapStateToProps = state => ({
  comments: state.prescription.comments,
  recipients: state.prescription.recipients,
  currentGraft: state.prescription.code
});

export default connect(mapStateToProps)(GraftStep5);
