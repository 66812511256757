import { createSlice } from "redux-starter-kit";

const grafts = createSlice({
  name: "grafts",
  initialState: [],
  reducers: {
    setGrafts: (state, action) => action.payload || []
  }
});

export const { setGrafts } = grafts.actions;
export default grafts.reducer;
