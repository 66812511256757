import styled from "styled-components/macro";

const Label = styled.label`
  color: var(--main-color);
  font-weight: bold;
  line-height: 1;
  margin-bottom: 10px;
`;

export default Label;
