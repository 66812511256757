import React from "react";
import ReactSVG from "react-svg";
import { connect } from "react-redux";
import styled from "styled-components/macro";
import { Link as BaseLink } from "react-router-dom";
import { get, map, replace } from "lodash";

import prescription from "../img/prescription.svg";
import certificat from "../img/certificat.svg";

import download from "../utils/download";

const BigButton = styled.div`
  background: #00a3e0;
  background: var(--main-color);
  text-align: center;
  padding: 30px 40px;
  border-radius: 10px;
  margin-top: 20px;

  svg {
    width: 30px;
    height: 30px;
  }
  &:hover {
    background: #004b87;
    background: var(--main-color-dark);
  }
`;

const BigButtonTitle = styled.div`
  margin-top: 20px;
  color: #fff;
`;

const Link = styled(BaseLink)``;

const Wrapper = styled.div`
  ${Link}, a {
    &:hover {
      text-decoration: none;
    }
  }
`;

const getPDF = ({ graftCode, downloadToken, practitionerCode }) => {
  const str = `${process.env.REACT_APP_API_BASE}/pdf?graftCode=${graftCode}&practitionerCode=${practitionerCode}&downloadToken=${downloadToken}`;
  return str;
};

function GraftStep2({ grafts, practitionerCode }) {
  return (
    <>
      <Wrapper>
        <p>
          Fiche d’administration – Prescription médicale et Certificat de
          validation
        </p>

        <Link to="/graft/3">
          <BigButton>
            <ReactSVG src={prescription} />
            <BigButtonTitle>Renseigner la prescription médicale</BigButtonTitle>
          </BigButton>
        </Link>
        {map(grafts, graft => (
          <a
            key={get(graft, "code")}
            href={getPDF({
              practitionerCode,
              graftCode: get(graft, "code"),
              downloadToken: get(graft, "downloadToken")
            })}
            target="_blank"
            rel="noopener noreferrer"
            onClick={e => {
              if (get(window, "cordova")) {
                e.preventDefault();
                const url = getPDF({
                  practitionerCode,
                  graftCode: get(graft, "code"),
                  downloadToken: get(graft, "downloadToken")
                });
                download(url, replace(get(graft, "code"), "/", "_"));
              }
            }}
          >
            <BigButton>
              <ReactSVG src={certificat} />
              <BigButtonTitle>
                Consulter le certificat de validation greffon n°{" "}
                {get(graft, "code")}
              </BigButtonTitle>
            </BigButton>
          </a>
        ))}

        <p
          css={`
            margin-top: 25px;
          `}
        >
          Une fois la prescription médicale renseignée, l’ensemble de ces
          documents sera envoyé automatiquement à l’adresse email que vous avez
          renseignée.
        </p>
      </Wrapper>
    </>
  );
}

const mapStateToProps = state => ({
  practitionerCode: state.practitioner.practitionerCode,
  grafts: state.grafts
});

export default connect(mapStateToProps)(GraftStep2);
