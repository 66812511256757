import React from "react";

import Content from "../components/Content";
import Page from "../layouts/Page";

function MentionsLegales() {
  return (
    <Page>
      <Content>
        <h1>
          Mentions l&eacute;gales de l&rsquo;application mobile / Portail web
        </h1>

        <h2>EDITEUR DE L&rsquo;APPLICATION</h2>

        <p>
          La pr&eacute;sente Application mobile est la propri&eacute;t&eacute;
          de la soci&eacute;t&eacute; OST DEVELOPPEMENT, agissant en
          qualit&eacute; d&rsquo;&eacute;diteur de l&rsquo;Application
          &laquo;&nbsp;EZ OST&nbsp;&raquo; / Portail web.
        </p>
        <p>
          Si&egrave;ge social&nbsp;: 15 Rue Georges Besse, 63100 &agrave;
          Clermont-Ferrand, France.
        </p>
        <p>
          Adresse email&nbsp;:{" "}
          <a href="mailto:systeme-information@ost-dev.com">
            systeme-information@ost-dev.com
          </a>
          .
        </p>
        <p>Num&eacute;ro de t&eacute;l&eacute;phone&nbsp;: 04.73.98.14.28.</p>
        <p>
          Soci&eacute;t&eacute; par actions simplifi&eacute;e, au capital social
          de 457&nbsp;200 euros,
        </p>
        <p>
          Immatricul&eacute;e au Registre du Commerce et des
          Soci&eacute;t&eacute;s sous le num&eacute;ro SIREN
          389&nbsp;472&nbsp;465.
        </p>
        <p>Directeur de la publication : Hubert GUIBOURT.</p>

        <h2>CONCEPTEUR DE L&rsquo;APPLICATION</h2>

        <p>
          La pr&eacute;sente Application a &eacute;t&eacute; con&ccedil;ue
          par&nbsp;la soci&eacute;t&eacute; OST DEVELOPPEMENT.
        </p>
        <p>
          Adresse email&nbsp;:{" "}
          <a href="mailto:systeme-information@ost-dev.com">
            systeme-information@ost-dev.com
          </a>
          .
        </p>
        <p>Num&eacute;ro de t&eacute;l&eacute;phone&nbsp;: 04.73.98.14.28.</p>

        <h2>HEBERGEUR DE L&rsquo;APPLICATION</h2>

        <p>
          La pr&eacute;sente Application est h&eacute;berg&eacute;e par la
          soci&eacute;t&eacute; OST DEVELOPPEMENT.
        </p>
        <p>
          Si&egrave;ge social&nbsp;: 15 Rue Georges Besse, 63100 &agrave;
          Clermont-Ferrand, France.
        </p>
        <p>N&deg; SIRET&nbsp;: 389&nbsp;472&nbsp;465.</p>
        <p>N&deg; TVA intracommunautaire&nbsp;: FR27389472465.</p>
        <p>
          Contact&nbsp;:{" "}
          <a href="mailto:systeme-information@ost-dev.com">
            systeme-information@ost-dev.com
          </a>
          .
        </p>
        <p>
          <br />
        </p>

        <h2>DESCRIPTION DES SERVICES FOURNIS</h2>

        <p>
          L&rsquo;Application &laquo;&nbsp;EZ OST&nbsp;&raquo; / Portail web a
          pour objectif de r&eacute;pondre &agrave; l&rsquo;obligation de son
          &eacute;diteur d&rsquo;assurer la tra&ccedil;abilit&eacute; de ses
          produits implantables et est &agrave; destination d&rsquo;un public
          professionnel m&eacute;dical.{" "}
        </p>

        <h2>PROPRIETE INTELLECTUELLE</h2>

        <p>
          La Soci&eacute;t&eacute; est le titulaire exclusif de tous les droits
          de propri&eacute;t&eacute; intellectuelle portant sur
          l&rsquo;Application et ses Contenus.
        </p>
        <p>
          Les Contenus sont au titre des droits d&rsquo;auteur et/ou autres
          droits de propri&eacute;t&eacute; intellectuelle. Ils sont la
          propri&eacute;t&eacute; exclusive de leurs &eacute;diteurs respectifs.
          Toute copie, reproduction, repr&eacute;sentation, exploitation,
          adaptation, alt&eacute;ration, modification, traduction, diffusion,
          int&eacute;grale ou partielle, des Contenus, qu&rsquo;ils
          appartiennent &agrave; la Soci&eacute;t&eacute; ou &agrave; un tiers
          qui a conc&eacute;d&eacute; des droits &agrave; la
          Soci&eacute;t&eacute;, par quelque proc&eacute;d&eacute; que ce soit,
          est illicite.{" "}
        </p>
        <p>
          Les Contenus pr&eacute;sent&eacute;s au sein de l&rsquo;Application
          sont susceptibles de modification sans pr&eacute;avis et sont mis
          &agrave; disposition sans aucune garantie d&rsquo;aucune sorte,
          expresse ou tacite et ne peuvent donner lieu &agrave; un quelconque
          droit &agrave; d&eacute;dommagement. Les Contenus
          prot&eacute;g&eacute;s peuvent notamment &ecirc;tre identifi&eacute;s
          par les mentions de r&eacute;serve suivantes&nbsp;:
          &laquo;&nbsp;&copy;&nbsp;&raquo; ou &laquo;&nbsp;Tous droits
          r&eacute;serv&eacute;s&nbsp;&raquo;.
        </p>
        <p>
          Les noms et logos apparaissant sur l&rsquo;Application sont des
          marques d&eacute;pos&eacute;es. Ces signes distinctifs sont la
          propri&eacute;t&eacute; exclusive de la Soci&eacute;t&eacute; ou de
          tiers ayant conc&eacute;d&eacute; des droits &agrave; la
          Soci&eacute;t&eacute;. Toute utilisation, reproduction totale ou
          partielle, ou imitation de ces marques&nbsp;est interdite, au sens des
          articles L.713-2 et suivants du Code de la propri&eacute;t&eacute;
          intellectuelle, sauf autorisation pr&eacute;alable et expresse de la
          Soci&eacute;t&eacute;.
        </p>

        <h2>LIMITE DE RESPONSABILITE</h2>

        <p>
          La Soci&eacute;t&eacute; fait ses meilleurs efforts pour
          v&eacute;rifier l&rsquo;exactitude des informations contenues sur son
          Application mais ne peut &ecirc;tre tenue responsable des omissions,
          inexactitudes et des carences dans la mise &agrave; jour de ces
          informations.
        </p>
        <p>
          La Soci&eacute;t&eacute; ne saurait &ecirc;tre tenue responsable de
          tout dommage direct ou indirect r&eacute;sultant de
          l&rsquo;acc&egrave;s &agrave; son Application (intrusion
          ext&eacute;rieure, pr&eacute;sence de virus informatique&hellip;) ou,
          au contraire, de l&rsquo;impossibilit&eacute; d&rsquo;y acc&eacute;der
          (rupture de service&hellip;).
        </p>
        <p>
          Toute demande d&rsquo;information en rapport avec le contenu ou le
          fonctionnement de l&rsquo;Application ainsi que le signalement de
          toute erreur ou omission peut &ecirc;tre adress&eacute;e &agrave;
          :&nbsp;
          <a href="mailto:systeme-information@ost-dev.com">
            systeme-information@ost-dev.com
          </a>
          .{" "}
        </p>
      </Content>
    </Page>
  );
}

export default MentionsLegales;
